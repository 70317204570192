
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import ydhl from '@/lib/ydhl'

export default {
  name: 'SSO',
  setup (props: any, context: any) {
    const error = ref()
    const { t } = useI18n()
    const api = ydhl.api
    onMounted(() => {
      const route = useRoute()
      // const router = useRouter()
      error.value = route.query.error
    })
    return {
      error,
      t,
      api
    }
  }
}
