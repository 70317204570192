import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent($setup.uiComponentWrap), {
      dragableCss: [{'ui': true, 'uicontainer':_ctx.isContainer, 'dragenter-container': _ctx.isDragIn
     ,'hover': _ctx.hoverUIItemId===$props.uiconfig.meta.id
     , 'selected': _ctx.selectedUIItemId===$props.uiconfig.meta.id}
     ],
      ref: "ui",
      key: $props.uiconfig.meta.id,
      uiconfig: $props.uiconfig,
      pageid: $props.pageid
    }, null, 8, ["dragableCss", "uiconfig", "pageid"])),
    _createVNode("div", {
      class: {'ui-hover': _ctx.hoverUIItemId===$props.uiconfig.meta.id, 'ui-selected': _ctx.selectedUIItemId===$props.uiconfig.meta.id},
      style: $setup.rectStyle
    }, " ", 6)
  ], 64))
}