
import Upload from '../../common/Upload.vue'
import Position from '../../common/Position.vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import initUI from '../../Common'

export default {
  name: 'BackgroundImage',
  components: { Position, Upload },
  props: {
    imageUrl: String,
    index: Number
  },
  setup (props: any, context: any) {
    const info = initUI()
    const localImage = computed({
      get () {
        const file = arrayGet('background-image', 'files') || []
        return { url: props.imageUrl, id: file?.id, name: file?.name }
      },
      set (v: any) {
        if (!v) {
          arrayRemove('background-image', 'style')
          arrayRemove('background-image', 'files')
          return
        }
        // console.log(v)
        arraySet('background-image', v.url, 'style')
        arraySet('background-image', { id: v.id, name: v.name }, 'files')
      }
    })

    const arraySet = (name, v, section) => {
      const arr = JSON.parse(JSON.stringify(info.getMeta(name, section) || []))
      arr[props.index] = v
      info.setMeta(name, arr, section)
    }
    const arrayGet = (name, section) => {
      const style = info.getMeta(name, section) || []
      if (style.length === 0) return ''
      return style[props.index]
    }
    const arrayRemove = (name, section) => {
      const arr = JSON.parse(JSON.stringify(info.getMeta(name, section) || []))
      arr.splice(props.index, 1)
      info.setMeta(name, arr, section)
    }
    const repeat = computed<string>({
      get () {
        return arrayGet('background-repeat', 'style')
      },
      set (v) {
        return arraySet('background-repeat', v, 'style')
      }
    })
    const size = computed({
      get () {
        const size = arrayGet('background-size', 'style')
        if (size !== 'contain' && size !== 'cover' && size) return 'length'
        return size
      },
      set (v) {
        arraySet('background-size', v === 'length' ? ' ' : v, 'style')
      }
    })
    const position = computed<Array<string>>({
      get () {
        const size = arrayGet('background-position', 'style') || ''
        return size.split(' ')
      },
      set (v) {
        // console.log(v)
        arraySet('background-position', v.join(' '), 'style')
      }
    })
    const positionX = computed<Array<string>>({
      get () {
        const size = arrayGet('background-position', 'style') || ''
        // console.log(size)
        return size.split(' ')[0] || ''
      },
      set (v) {
        const old = JSON.parse(JSON.stringify(info.getMeta('background-position', 'style') || []))
        const arr = old[props.index].split(' ') || []
        arr[0] = v
        old[props.index] = arr.join(' ')
        info.setMeta('background-position', old, 'style')
      }
    })
    const positionY = computed<Array<string>>({
      get () {
        const size = arrayGet('background-position', 'style') || ''
        return size.split(' ')[1] || ''
      },
      set (v) {
        const old = JSON.parse(JSON.stringify(info.getMeta('background-position', 'style') || []))
        const arr = old[props.index].split(' ') || []
        arr[1] = v
        old[props.index] = arr.join(' ')
        info.setMeta('background-position', old, 'style')
      }
    })
    const sizeW = computed<Array<string>>({
      get () {
        const _sizeValue = size.value
        if (_sizeValue !== 'length') return ''
        const _size = arrayGet('background-size', 'style')
        return _size.split(' ')[0] || ''
      },
      set (v) {
        const old = JSON.parse(JSON.stringify(info.getMeta('background-size', 'style') || []))
        const arr = old[props.index].split(' ') || []
        arr[0] = v
        old[props.index] = arr.join(' ')
        info.setMeta('background-size', old, 'style')
      }
    })
    const sizeH = computed<Array<string>>({
      get () {
        const _sizeValue = size.value
        if (_sizeValue !== 'length') return ''
        const _size = arrayGet('background-size', 'style')
        return _size.split(' ')[1] || ''
      },
      set (v) {
        const old = JSON.parse(JSON.stringify(info.getMeta('background-size', 'style') || []))
        const arr = old[props.index].split(' ') || []
        arr[1] = v
        old[props.index] = arr.join(' ')
        info.setMeta('background-size', old, 'style')
      }
    })
    const clip = computed<Array<string>>({
      get () {
        return arrayGet('background-clip', 'style')
      },
      set (v) {
        arraySet('background-clip', v, 'style')
      }
    })

    const origin = computed<Array<string>>({
      get () {
        return arrayGet('background-origin', 'style')
      },
      set (v) {
        arraySet('background-origin', v, 'style')
      }
    })
    const attachment = computed<Array<string>>({
      get () {
        return arrayGet('background-attachment', 'style')
      },
      set (v) {
        arraySet('background-attachment', v, 'style')
      }
    })

    const removeImage = () => {
      arrayRemove('background-image', 'style')
      arrayRemove('background-image', 'files')
      // 对应的其他Background属性也删除
      arrayRemove('background-position', 'style')
      arrayRemove('background-size', 'style')
      arrayRemove('background-repeat', 'style')
      arrayRemove('background-clip', 'style')
      arrayRemove('background-origin', 'style')
      arrayRemove('background-attachment', 'style')
    }

    const { t } = useI18n()
    return {
      t,
      removeImage,
      localImage,
      repeat,
      size,
      sizeW,
      sizeH,
      origin,
      clip,
      attachment,
      positionX,
      positionY,
      position
    }
  }
}
