<template>
  <div class="p-5 text-muted">TODO：icon 图标资源</div>
</template>

<script>
export default {
  name: 'Icon'
}
</script>

<style scoped>

</style>
