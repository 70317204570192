
import TopPanel from '@/components/page/TopPanel.vue'
import RightPanel from '@/components/page/RightPanel.vue'
import LeftPanel from '@/components/page/LeftPanel.vue'
import WorkspacePanel from '@/components/page/WorkspacePanel.vue'
import { computed, onMounted, getCurrentInstance, toRaw, ref } from 'vue'
import { useStore } from 'vuex'
import ydhl from '@/lib/ydhl'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { YDJSStatic } from '@/lib/ydjs'
import { pickStateFromDesign } from '@/store/page'

declare const YDJS: YDJSStatic

export default {
  components: {
    TopPanel,
    LeftPanel,
    WorkspacePanel,
    RightPanel
  },
  setup (props: any, context: any) {
    const store = useStore()
    const route = useRoute()
    const workspace = ref()
    const router = useRouter()
    const loaded = computed(() => {
      return store.state.design.function.id
    })
    const backdropVisible = computed(() => {
      return store.state.design.backdropVisible
    })
    const { t } = useI18n()
    const saved = computed(() => store.state.design.saved)
    const currFunction = computed(() => store.state.design.function)
    const loadContent = (pageId, functionId, cb) => {
      const loadingId = YDJS.loading(t('common.pleaseWait'))
      if (socket.value && pageId) socket.value.send(JSON.stringify({ uuid: pageId, token: ydhl.getJwt(), leavePageId: page.value?.meta?.id, action: 'uibuilder', lang: ydhl.getLanguage() }))

      ydhl.get('api/load.json', { pageId, functionId }, (rst) => {
        YDJS.hide_dialog(loadingId)
        if (!rst || !rst.success) {
          router.push({
            path: '/error',
            query: {
              error: rst ? rst.msg : 'Oops, Please try again'
            }
          })
          return
        }
        const designData: any = rst.data.design
        const userData: any = rst.data.user

        const lang: any = rst.data.lang
        const ctx:any = getCurrentInstance()
        if (ctx != null) ctx.$i18n.locale = lang

        store.commit('cleanState')
        const design: any = JSON.parse(JSON.stringify(oldDesign.value))
        for (const dataKey in designData) {
          design[dataKey] = designData[dataKey]
        }
        // 初始化本地相关数据
        design.saved = -1
        design.socket = socket.value
        const user: any = JSON.parse(JSON.stringify(oldUser.value))
        for (const dataKey in userData) {
          user[dataKey] = userData[dataKey]
        }
        const css = rst.data.predefineCSS
        css.cssTranslate = rst.data.cssTranslate
        store.replaceState({ design, user, css })
        if (cb) cb()
      })
    }
    const socket = computed(() => store.state.design.socket)
    const page = computed(() => store.state.design.page)
    const project = computed(() => store.state.design.project)
    const oldDesign = computed(() => store.state.design)
    const oldUser = computed(() => store.state.user)
    const canEdit = computed(() => store.state.design.canEdit)
    onBeforeRouteUpdate((to, from) => {
      if (to?.fullPath === from?.fullPath) return // 点击右边边栏会触发onBeforeRouteUpdate，原因未知
      // 地址发生变化，跳转到其他页面，加载目标页面内容
      loadContent(to.query.uuid, to.query.functionId, null)
    })

    const connectSocket = () => {
      // 建立socket连接
      const socket = new WebSocket(ydhl.socket)
      socket.addEventListener('open', function (event) {
        store.commit('updateState', { socket })
        if (route.query.uuid) socket.send(JSON.stringify({ uuid: route.query.uuid, token: ydhl.getJwt(), action: 'uibuilder', lang: ydhl.getLanguage() }))
      })
      socket.addEventListener('close', function (event) {
        // socket 关闭后 server端会把我打开端所有页面信息page user清空
        store.commit('updateState', { socket: null, userList: [] })
      })
      socket.addEventListener('error', function (event) {
      })
      socket.addEventListener('message', function (event) {
        let rst
        try {
          rst = JSON.parse(event.data) || null
        } catch (e) {}
        if (!rst || !rst.type) return
        switch (rst.type) {
          case 'error': {
            YDJS.alert(rst.msg, 'Tip', YDJS.ICON_INFO, [
              {
                label: t('common.ok'),
                cb: function (dialogid) {
                  document.location.href = project?.value?.id ? (ydhl.api + 'project/' + project?.value?.id) : ydhl.api + 'dashboard'
                }
              }])
            return
          }
          case 'userList': {
            store.commit('updateState', { userList: rst.userList })
            break
          }
          case 'deletedPage': {
            if (rst.pageid === page.value?.meta?.id) {
              store.commit('updateState', { userList: [], saved: -1, page: null })
              YDJS.alert(t('common.pageHasBeenDeletedByOtherUser', [rst.user]), 'Tip', YDJS.ICON_INFO, [
                {
                  label: t('common.ok'),
                  cb: function (dialogid) {
                    document.location.href = ydhl.api + 'project/' + project.value.id
                  }
                }])
            }
            break
          }
          case 'modifiedPage': {
            if (rst.pageid === page.value?.meta?.id) {
              YDJS.alert(t('common.pageHasBeenModifiedByOtherUser', [rst.user]), 'Tip', YDJS.ICON_INFO, [
                {
                  label: t('common.ok'),
                  cb: function (dialogid) {
                    YDJS.hide_dialog(dialogid)
                    loadContent(page.value.meta.id, currFunction.value.id, () => {
                      const rawState = toRaw(store.state.design)
                      workspace.value.postMessage(page.value.meta.id, { type: 'state', state: { page: pickStateFromDesign(event.data.pageId, rawState), css: toRaw(store.state.css) } })
                    })
                  }
                }])
            }
            break
          }
        }
      })
    }
    onMounted(() => {
      loadContent(route.query.uuid, route.query.functionId, null)

      // 未保存时退出提示
      window.addEventListener('beforeunload', function (e) {
        if (saved.value !== 0 || !canEdit.value) return
        e.preventDefault()
        e.returnValue = t('common.notSaveInfo')
        return t('common.notSaveInfo')
      })
      // 定时检查socket 是否连接上
      setInterval(() => {
        if (socket.value) return
        connectSocket()
      }, 5000)
    })
    return {
      backdropVisible,
      loaded,
      canEdit,
      workspace,
      t
    }
  },
  name: 'Home'
}
