<template>
  <div>
    <button type="button" class="btn btn-block btn-outline-secondary btn-sm w-100">Gradient</button>
  </div>
</template>

<script>
export default {
  name: 'Gradient'
}
</script>
