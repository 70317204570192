
import InitUITree from '@/components/Common'
import { computed, nextTick, Ref, ref, watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'UITreeLoader',
  props: {
    uiconfig: Object,
    tab: Number,
    isOpen: Boolean,
    pageid: String,
    treeIcon: String
  },
  emits: ['onInlineEdit'],
  setup (props: any, context: any) {
    const store = useStore()
    const init = InitUITree()
    const input: Ref<HTMLElement | null> = ref(null)
    const isContainer = computed(() => props.uiconfig.meta.isContainer)
    const dragoverUIItemId = computed(() => store.state.design.dragoverUIItemId)
    const dragoverPlacement = computed(() => store.state.design.dragoverPlacement)
    const itemWidthStyle = computed(() => `width:${store.state.design.rightSidebarWidth - 5}px`)// 5是滚动条的宽度，和variables.scss中的scrollbarwidth一致
    const isDragIn = computed(() => {
      if (dragoverPlacement.value !== 'in') return false
      return dragoverUIItemId.value === props.uiconfig.meta.id
    })
    const isInEdit = ref(false)
    const eventCount = computed(() => {
      if (!props.uiconfig?.events) return 0
      return Object.keys(props.uiconfig.events).length
    })
    const value = computed({
      get () {
        return init.getMeta('value')
      },
      set (v) {
        init.setMeta('value', v)
      }
    })
    const title = computed({
      get () {
        return init.getMeta('title')
      },
      set (v) {
        init.setMeta('title', v)
      }
    })
    const otherInfo = computed(() => {
      const container = props.uiconfig.meta?.css?.container
      return container ? ` (${container}) ` : ''
    })
    watch(isInEdit, (v) => {
      nextTick(() => {
        if (v && input.value) {
          // console.log('need click')
          input.value.focus()
        }
      })
      context.emit('onInlineEdit', v)
    })
    return {
      isContainer,
      isDragIn,
      itemWidthStyle,
      isInEdit,
      value,
      title,
      input,
      eventCount,
      otherInfo,
      ...init
    }
  }
}
