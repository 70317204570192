
import InitUI from '@/components/Common'
import { ref, computed, watch, onMounted, nextTick, toRaw } from 'vue'
import $ from 'jquery'
import ydhl from '@/lib/ydhl'
import { useStore } from 'vuex'
import ElementPath from '@/components/page/ElementPath.vue'
import { useI18n } from 'vue-i18n'
import { pickStateFromDesign } from '@/store/page'
import PageDesign from '@/components/page/PageDesign.vue'
import { YDJSStatic } from '@/lib/ydjs'
import keyevent from '@/lib/keyevent'
declare const YDJS: YDJSStatic
declare const ports: any

export default {
  components: {
    PageDesign,
    ElementPath
  },
  setup (props: any, context: any) {
    const workspaceStyle = ref('')
    const headStyle = ref('')
    const leftHasMore = ref(false)
    const rightHasMore = ref(false)
    const zoomMenuVisible = ref(false)
    const zoomLevel = ref([3, 2, 1.25, 1, 0.75, 0.5, 0.3])
    const headScrollbar = ref(null)
    const head = ref(null)
    const zoomMenu = ref()
    const store = useStore()
    let copyedItems: Record<string, any> = {}

    const leftSidebarWidth = computed(() => store.state.design.leftSidebarWidth)
    const rightSidebarWidth = computed(() => store.state.design.rightSidebarWidth)
    const rightSidebarIsOpen = computed(() => store.state.design.rightSidebarIsOpen)
    const leftSidebars = computed(() => store.state.design.leftSidebars)
    const dragoverUIItemId = computed(() => store.state.design.dragoverUIItemId)
    const dragoverPlacement = computed(() => store.state.design.dragoverPlacement)
    const currFunctionId = computed(() => store.state.design.function.id)
    const simulateWidth = computed(() => store.state.design.simulateWidth)
    const simulateHeight = computed(() => store.state.design.simulateHeight)
    const pageScale = computed(() => store.state.design.scale)
    const moduleId = computed(() => store.state.design.module.id)
    const selectedPageId = computed(() => store.state.design?.page?.meta?.id)

    const { ui, uiVersion, hoverUIItemId, selectedUIItemId } = InitUI()

    const changeZoom = (scale: number) => {
      store.commit('updatePageState', { scale })
      zoomMenuVisible.value = false
    }
    const zoomin = () => {
      let scale = pageScale.value + 0.1
      if (scale > 3) scale = 3
      changeZoom(scale)
    }
    const zoomout = () => {
      let scale = pageScale.value - 0.1
      if (scale < 0.1) scale = 0.1
      changeZoom(scale)
    }
    const openZoomMenu = (event: any) => {
      ydhl.togglePopper(zoomMenuVisible, event.target, zoomMenu, 'bottom')
    }
    /**
     * 根据左右边栏的打开尺寸，计算workdspace的宽度和head的宽度，如果
     * workspace的宽度小于了页面的宽度，则更新design.scale让页面进行缩放
     */
    const computeStyle = () => {
      // debugger
      const rightPanelWidth = rightSidebarIsOpen.value ? rightSidebarWidth.value : 0
      const _leftSidebarWidth = leftSidebars.value.length > 0 ? leftSidebarWidth.value : 0
      const dom = document.getElementById('left-panel')
      const leftPanelWidth = dom ? dom.getBoundingClientRect().width || 0 : 0

      const leftWidth = _leftSidebarWidth + leftPanelWidth
      const pageWidth = simulateWidth.value // 模拟页面的宽度，0表示自适应的

      const _workspaceStyle: any = []
      _workspaceStyle.push(`left:${leftWidth}px`)
      _workspaceStyle.push(`right:${rightPanelWidth}px`)
      // _workspaceStyle.push(`min-height:${document.body.clientHeight}px`)
      // workspaceStyle.push(`transform:scale(${scale})`)
      // workspaceStyle.push(`width:${originWidth}px`)
      _workspaceStyle.push(`width: ${document.body.clientWidth - leftWidth - rightPanelWidth}px`)

      const _headStyle: any = []
      _headStyle.push(`left:${leftWidth}px`)
      _headStyle.push(`right:${rightSidebarWidth.value}px;`)
      _headStyle.push(`width:${document.body.clientWidth - leftWidth - rightSidebarWidth.value}px`)

      workspaceStyle.value = _workspaceStyle.join(';')
      headStyle.value = _headStyle.join(';')

      nextTick(() => {
        // 计算workspace的缩放比例
        const workspace = document.querySelector('.workspace-scrollbar')
        let newWidth = 0
        if (workspace) {
          const paddingRightOfWorkspace = ydhl.formatFloat(window.getComputedStyle(workspace).getPropertyValue('padding-right'))
          const paddingLeftOfWorkspace = ydhl.formatFloat(window.getComputedStyle(workspace).getPropertyValue('padding-left'))
          // debugger
          newWidth = workspace.getBoundingClientRect().width - paddingLeftOfWorkspace - paddingRightOfWorkspace
        } else {
          newWidth = document.body.clientWidth - leftWidth - rightPanelWidth - 100
        }
        const originWidth = pageWidth || (document.body.clientWidth - leftPanelWidth - 100) // 100是workspace body左右的间距
        const scale = Math.min(newWidth / originWidth, 1)
        // console.log(`${newWidth} / ${originWidth} ${scale}`)
        store.commit('updatePageState', { scale })
      })
    }

    const changeHeadMoreState = () => {
      leftHasMore.value = ($(headScrollbar.value as any).scrollLeft() || 0) > 0
      rightHasMore.value = (($(head.value as any).width() || 0) + ($(headScrollbar.value as any).scrollLeft() || 0)) < (headScrollbar.value as any).scrollWidth
      // console.log($(this.$refs.head), $(this.$refs.headScrollbar).scrollLeft(), $(this.$refs.head).width(), this.$refs.headScrollbar.scrollWidth)
    }
    const headScroll = (event: any) => {
      if (leftHasMore.value || rightHasMore.value) {
        event.stopPropagation()
        event.preventDefault()
        event.cancelBubble = true
      }
      changeHeadMoreState()
    }
    const headWheel = (event: any) => {
      if (leftHasMore.value || rightHasMore.value) {
        event.stopPropagation()
        event.preventDefault()
        event.cancelBubble = true
      }
      const _headScrollbar = $(headScrollbar.value as any)
      // console.log(event);
      // event.deltaY 滚动方向与距离
      const scrollLeft = _headScrollbar.scrollLeft() || 0
      _headScrollbar.scrollLeft((scrollLeft + event.deltaY))
    }

    watch(leftSidebars, () => {
      computeStyle()
    })
    watch(rightSidebarIsOpen, () => {
      computeStyle()
    })
    watch(rightSidebarWidth, () => {
      computeStyle()
      changeHeadMoreState()
    })
    watch(leftSidebarWidth, () => {
      computeStyle()
      changeHeadMoreState()
    })
    const postMessage = (pageid, msg) => {
      // console.log(pageid, ports[pageid])
      msg.pageId = pageid
      if (pageid && ports?.[pageid]) {
        ports[pageid](msg)
      }
    }
    const copyItem = (ids, pageId) => {
      copyedItems = {}
      if (!ids) return
      for (const id of ids) {
        const { uiConfig } = store.getters.getUIItemInPage(id, pageId)
        copyedItems[id] = JSON.parse(JSON.stringify(toRaw(uiConfig)))
      }
    }
    const onMessage = (data) => {
      if (!data) return
      if (data.type === 'updatePageContentHeight' && data.data) {
        store.commit('updatePageContentHeight', data.data)
        return
      }
      if (data.type === 'update' && data.data) {
        store.commit('updatePageState', data.data)
        return
      }
      if (data.type === 'deleteItem' && data.pageId) {
        store.commit('deleteItem', { ids: data.data.ids, pageId: data.pageId })
        return
      }
      if (data.type === 'cutItem' && data.pageId) {
        copyItem(data.data.ids, data.pageId)
        store.commit('deleteItem', { ids: data.data.ids, pageId: data.pageId })
        return
      }
      if (data.type === 'copyItem' && data.pageId) {
        copyItem(data.data.ids, data.pageId)
        return
      }
      if (data.type === 'pasteItem' && data.pageId) {
        if (!data.data.ids || data.data.ids.length === 0) return
        const { uiConfig } = store.getters.getUIItemInPage(data.data.ids[0], data.pageId)
        if (!uiConfig) return
        // console.log(copyedItems)
        for (const copyId in copyedItems) {
          const type = copyedItems[copyId].type
          const meta = JSON.parse(JSON.stringify(copyedItems[copyId].meta))
          const items = JSON.parse(JSON.stringify(copyedItems[copyId].items))
          meta.id = ydhl.uuid(10, 16, type)
          // console.log(meta.id + ' ' + (uiConfig.meta.isContainer ? 'in' : 'bottom'))
          store.commit('addItem', {
            type,
            meta,
            items,
            placeInParent: '',
            pageId: data.pageId,
            placement: uiConfig.meta.isContainer ? 'in' : 'bottom',
            targetId: data.data.ids[0]
          })
        }
        return
      }
      if (data.type === 'moveItem') {
        store.commit('moveItem', data.data)
        return
      }
      if (data.type === 'addItem') {
        store.commit('addItem', data.data)
        return
      }
      if (data.type === 'updateItemMeta') {
        store.commit('updateItemMeta', data.data)
      }
    }
    onMounted(() => {
      $('body').on('click', (event: any) => {
        if ($(event.target).parents('#zoomMenu').length === 0) {
          zoomMenuVisible.value = false
        }
      })

      $('body').on('click', '.workspace-scrollbar', (event) => {
        store.commit('updatePageState', { selectedUIItemId: '' })
      })

      computeStyle()

      nextTick(() => {
        changeHeadMoreState()
      })

      // 注册消息通知函数, 当有postmessage消息过来时，说明iframe已经准备好了，这时想iframe的ports注册自己的回调函数
      window.addEventListener('message', (event) => {
        if (!event.data) return
        if (event.data.type === 'loaded' && event.data.pageId) {
          const iframe: any = document.getElementById(event.data.pageId)
          if (!iframe) return
          iframe.contentWindow.ports.parent = onMessage

          const rawState = toRaw(store.state.design)
          postMessage(event.data.pageId, { type: 'state', state: { page: pickStateFromDesign(event.data.pageId, rawState), css: toRaw(store.state.css) } })
        }
      }, false)

      const { userAgent } = navigator
      const isMac = userAgent.includes('Macintosh')
      const modKey = (isMac ? 'meta' : 'ctrl') // ⌘
      // 删除事件通知给iframe去处理
      keyevent.keydown('backspace', (event) => {
        // console.log('workspace backspace')
        if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') return
        event.preventDefault()
        event.cancelBubble = true // IE
        postMessage(selectedPageId.value, { type: 'deleteItem' })
      })
      keyevent.keydown([modKey, 'c'], (event) => {
        if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') return
        event.preventDefault()
        event.cancelBubble = true // IE
        postMessage(selectedPageId.value, { type: 'copyItem' })
      })
      keyevent.keydown([modKey, 'x'], (event) => {
        if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') return
        event.preventDefault()
        event.cancelBubble = true // IE
        postMessage(selectedPageId.value, { type: 'cutItem' })
      })
      keyevent.keydown([modKey, 'v'], (event) => {
        if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') return
        event.preventDefault()
        event.cancelBubble = true // IE
        postMessage(selectedPageId.value, { type: 'pasteItem' })
      })
    })
    const addPage = () => {
      store.commit('addPage', { pageType: 'page' })
    }
    const currPage = computed(() => store.state.design.page)
    const versionId = computed(() => store.state.design.versionId)

    const preview = (event) => {
      const loadingId = YDJS.loading(t('common.pleaseWait'))
      ydhl.savePage(currFunctionId.value, currPage.value, versionId.value, (rst) => {
        YDJS.hide_dialog(loadingId)
        if (rst?.success) {
          store.commit('updateState', { saved: 1, versionId: rst.data.versionId })
        }
        const link = document.getElementById('openlink')
        if (link) {
          link.setAttribute('href', ydhl.api + 'preview/' + moduleId.value + '?page=' + currPage.value?.meta.id)
          link.click()
        }
      })
    }
    const { t } = useI18n()

    const project = computed(() => store.state.design.project)
    const module = computed(() => store.state.design.module)
    const func = computed(() => store.state.design.function)
    const userList = computed(() => store.state.design.userList)

    const endKind = computed(() => {
      return store.state.design.endKind
    })
    const isModileKind = computed(() => {
      return endKind.value === 'mobile'
    })
    const simulateModel = computed({
      get () {
        return store.state.design.simulateModel || 'pc'
      },
      set (v) {
        store.commit('updateState', { simulateModel: v })
      }
    })
    const pageTitle = computed(() => {
      return project.value.name + '/' + module.value.name + '/' + func.value.name + '/' + currPage.value?.meta?.title + '【YDUIBuilder】'
    })
    const openCooperationTip = () => {
      YDJS.alert(t('common.somebodyComeInTip'), 'Tips')
    }
    const switchEventShow = computed({
      get () {
        return store.state.design.showEventPanel
      },
      set (v) {
        store.commit('switchEventShow', v)
      }
    })
    watch(pageTitle, (v) => {
      document.title = v
    }, {
      immediate: true
    })

    return {
      t,
      hoverUIItemId,
      selectedUIItemId,
      leftSidebarWidth,
      rightSidebarWidth,
      rightSidebarIsOpen,
      dragoverUIItemId,
      dragoverPlacement,
      isModileKind,
      ui,
      uiVersion,
      simulateWidth,
      simulateHeight,
      pageScale,
      workspaceStyle,
      headStyle,
      leftHasMore,
      rightHasMore,
      zoomMenuVisible,
      zoomLevel,
      head,
      headScrollbar,
      zoomMenu,
      currPage,
      pageTitle,
      project,
      func,
      module,
      simulateModel,
      userList,
      endKind,
      changeZoom,
      postMessage,
      openCooperationTip,
      zoomin,
      zoomout,
      preview,
      openZoomMenu,
      headScroll,
      headWheel,
      changeHeadMoreState,
      switchEventShow,
      addPage
    }
  }
}
