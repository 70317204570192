
import { useI18n } from 'vue-i18n'
import ydhl from '@/lib/ydhl'
import { computed, nextTick, ref } from 'vue'
import { useStore } from 'vuex'
import { YDJSStatic } from '@/lib/ydjs'
import $ from 'jquery'
declare const bootstrap: any
declare const YDJS: YDJSStatic

export default {
  name: 'TopPanel',
  setup (props: any, ctx: any) {
    const { t } = useI18n()
    const store = useStore()
    const userAvatar = computed(() => store.state.user.avatar)
    const canEdit = computed(() => store.state.design.canEdit)
    const userRole = computed(() => store.state.design.userRole)
    const project = computed(() => store.state.design.project)
    const uiName = computed(() => store.state.design.project.ui + store.state.design.project.ui_version)
    const saveStatus = computed(() => store.state.design.saved)
    const currFunctionId = computed(() => store.state.design.function.id)
    const currPage = computed(() => store.state.design.page)
    const socket = computed(() => store.state.design.socket)
    const versionId = computed(() => store.state.design.versionId)
    const api = ydhl.api + 'project/' + project.value.id
    const isOpenBuildDialog = ref(false)
    const saveWord = computed(() => {
      if (saveStatus.value === 1) {
        return t('common.saved')
      } else if (saveStatus.value === -1) {
        return t('common.save')
      } else {
        return t('common.notsave')
      }
    })
    const save = function () {
      ydhl.savePage(currFunctionId.value, currPage.value, versionId.value, (rst) => {
        if (rst?.success) {
          store.commit('updateState', { saved: 1, versionId: rst.data.versionId })
        }
      })
    }
    const buildSocketMsg = (event) => {
      if (event.data === 'done') {
        socket.value.removeEventListener('message', buildSocketMsg)
        return
      }
      const log = $('#build-log')
      log.append(`<div>${event.data}</div>`)
      log.get(0).scrollTo({ top: log.get(0).scrollHeight })
    }
    const build = () => {
      isOpenBuildDialog.value = true
      nextTick(() => {
        const myModalEl = document.getElementById('openBuildDialog') as HTMLElement
        const myModal = new bootstrap.Modal(myModalEl)
        myModalEl.addEventListener('hide.bs.modal', function (event) {
          isOpenBuildDialog.value = false
          $('#openBuildDialog').remove()
        })
        myModal.show()

        if (!socket.value) return

        const log = $('#build-log')

        socket.value.addEventListener('message', buildSocketMsg)

        socket.value.send(JSON.stringify({ uuid: project.value?.id, token: ydhl.getJwt(), action: 'build', lang: ydhl.getLanguage() }))
        log.append('<div>Connected</div>')
      })
    }
    const setting = () => {
      const dialogid = YDJS.dialog(`${api}/tech`
        , ''
        , t('project.setting')
        , YDJS.SIZE_NORMAL
        , YDJS.BACKDROP_STATIC
        , []
        , undefined
        , (rst) => {
          YDJS.hide_dialog(dialogid)
          console.log(rst)
          if (!rst || !rst.success) {
            YDJS.toast(rst?.msg || t('common.operationFail'), YDJS.ICON_ERROR)
          } else {
            document.location.reload()
          }
        })
    }
    return {
      t,
      save,
      saveStatus,
      canEdit,
      api,
      project,
      socket,
      uiName,
      userAvatar,
      isOpenBuildDialog,
      saveWord,
      userRole,
      setting,
      build
    }
  }
}
