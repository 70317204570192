
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  props: {
    uiconfig: Object,
    pageid: String
  },
  name: 'UIEventBadge',
  setup (props: any, context: any) {
    const store = useStore()
    const eventCount = computed(() => {
      if (!props.uiconfig?.events) return 0
      return Object.keys(props.uiconfig.events).length
    })

    const loaded = computed(() => store.state.page.loadedUIIds?.[props.uiconfig?.meta?.id])
    const showEventPanel = computed(() => store.state.page.showEventPanel)

    const positionStyle = computed(() => {
      // console.log(showEventPanel.value)
      if (!loaded.value || !showEventPanel.value) return
      const rect = document.getElementById(props.uiconfig?.meta?.id)?.getBoundingClientRect()
      if (!rect) return
      return `transform:translate(${rect.x}px,${rect.y}px); width:${rect.width}px;height:${rect.height}px`
    })
    return {
      eventCount,
      showEventPanel,
      loaded,
      positionStyle
    }
  }
}
