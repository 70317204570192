import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "popup-design-tip"
}
const _hoisted_2 = { class: "list-group w-50" }
const _hoisted_3 = { class: "list-group-item disabled" }
const _hoisted_4 = {
  key: 0,
  id: "ui-events"
}
const _hoisted_5 = {
  key: 1,
  style: {"color":"#cccccc","padding":"30px","text-align":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIBase = _resolveComponent("UIBase")
  const _component_UIEventBadge = _resolveComponent("UIEventBadge")

  return (_openBlock(), _createBlock(_Fragment, null, [
    ($setup.currPage)
      ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
          ($setup.isPopup)
            ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                ($setup.isEmptyPopup)
                  ? (_openBlock(), _createBlock("div", _hoisted_1, [
                      _createVNode("div", _hoisted_2, [
                        _createVNode("div", _hoisted_3, _toDisplayString($setup.t('page.pleaseChoosePopupType')), 1),
                        _createVNode("a", {
                          href: "javascript:;",
                          class: "list-group-item list-group-item-action",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.addPopup('toast')))
                        }, _toDisplayString($setup.t('page.toast')), 1),
                        _createVNode("a", {
                          href: "javascript:;",
                          class: "list-group-item list-group-item-action",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.addPopup('alert')))
                        }, _toDisplayString($setup.t('page.alert')), 1),
                        _createVNode("a", {
                          href: "javascript:;",
                          class: "list-group-item list-group-item-action",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.addPopup('confirm')))
                        }, _toDisplayString($setup.t('page.confirm')), 1),
                        _createVNode("a", {
                          href: "javascript:;",
                          class: "list-group-item list-group-item-action",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.addPopup('prompt')))
                        }, _toDisplayString($setup.t('page.prompt')), 1),
                        _createVNode("a", {
                          href: "javascript:;",
                          class: "list-group-item list-group-item-action",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.addPopup('custom')))
                        }, _toDisplayString($setup.t('page.custom')), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createVNode("div", {
                  class: "popup-background",
                  style: $setup.popPlacementStyle
                }, [
                  (!$setup.isEmptyPopup)
                    ? (_openBlock(), _createBlock(_component_UIBase, {
                        key: 0,
                        uiconfig: $setup.currPage,
                        pageid: $setup.currPage.meta.id
                      }, null, 8, ["uiconfig", "pageid"]))
                    : _createCommentVNode("", true)
                ], 4),
                _createVNode("div", {
                  class: "popup-mask",
                  style: $setup.backgroundImage
                }, null, 4)
              ], 64))
            : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_UIBase, {
                  uiconfig: $setup.currPage,
                  pageid: $setup.currPage.meta.id
                }, null, 8, ["uiconfig", "pageid"]),
                ($setup.showEventPanel)
                  ? (_openBlock(), _createBlock("div", _hoisted_4, [
                      _createVNode(_component_UIEventBadge, {
                        uiconfig: $setup.currPage,
                        pageid: $setup.currPage.meta.id
                      }, null, 8, ["uiconfig", "pageid"])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
        ], 64))
      : _createCommentVNode("", true),
    (!$setup.currPage)
      ? (_openBlock(), _createBlock("div", _hoisted_5, _toDisplayString($setup.t('page.loading')), 1))
      : _createCommentVNode("", true),
    ($setup.dragoverUIItemId)
      ? (_openBlock(), _createBlock("div", {
          key: 2,
          id: "drop-placeholder",
          style: $setup.dragoverHolderStyle
        }, null, 4))
      : _createCommentVNode("", true)
  ], 64))
}