export default {
  page: {
    pleaseChoosePopupType: '请选择弹窗类型',
    toast: 'Toast',
    alert: 'Alert 对话框',
    prompt: 'Prompt 对话框',
    confirm: 'Confirm 对话框',
    custom: '自定义',
    choosePage: '选择已有页面',
    name: '页面',
    setting: '设置',
    exportSetting: '导出设置',
    title: '标题',
    loading: '加载',
    file: '页面名称',
    fileTip: '页面导出后的文件名，不包含后缀',
    url: 'URL',
    homePage: '主页',
    urlTip: '页面的映射URL，/打头',
    blank: '空白页面',
    editPage: '点击修改',
    copyPage: '复制页面',
    code: '导出代码',
    deletePage: '放入回收站',
    deletePageConfirm: '放入回收站后，你可随时找回来',
    lockPage: '锁定/解锁该页面'
  },
  ui: {
    common: '通用组件',
    form: '表单组件',
    navbar: '导航栏',
    utilities: '其他',
    page: '页面',
    component: '组件',
    base: '基础组件',
    text: '文本',
    nav: '标签组',
    hr: '分割线',
    button: '按钮',
    buttonGroup: '按钮组',
    buttonGroupEmptyTip: '按钮组中只能添加{0}',
    addItemTypeError: '不能在{1}中添加{0}, 只能添加{2}',
    select: '下拉框',
    textarea: '文本框',
    breadcrumb: '面包屑',
    card: '卡片',
    modal: '模态框',
    checkbox: '复选框',
    image: '图像',
    input: '输入框',
    rangeInput: '滑动条',
    list: '列表',
    pagination: '分页',
    progress: '进度条',
    radio: '单选框',
    dropdown: '下拉菜单',
    container: '容器',
    file: '文件',
    framework: 'UI框架',
    icon: '图标',
    table: '表格'
  },
  module: {
    addFunction: '添加功能',
    addModule: '添加模块',
    deleteModule: '删除模块',
    deleteModuleConfirm: '该操作不能撤销，模块下面的功能，api，ui等都将被删除，请输入模块名{0}确认删除'
  },
  common: {
    operationFail: '操作失败，请重试',
    event: '事件',
    eventShow: '显示事件',
    eventShowDesc: '显示ui元素绑定的事件信息',
    readonly: '只读',
    socketNotConnect: 'Socket 链接已断开',
    notSaveInfo: '你的内容未保存，确定要离开吗？',
    pageHasBeenDeletedByOtherUser: '该页面已被{0}删除了',
    pageHasBeenModifiedByOtherUser: '该页面已被{0}修改了，当前不是最新版本，确定后将重新加载页面',
    somebodyComeIn: '其他用户',
    somebodyComeInTip: '有人打开了该页面，多人打开该页面时最好只由某人来编辑，避免保存冲突',
    orCustom: '自定义',
    snapshotting: '截图中',
    idIsInvalidate: 'ID无效',
    dragtohere: '拖动UI到这里',
    goBack: '回退',
    none: '无',
    edit: '编辑',
    import: '倒入',
    page: '页面',
    addPage: '新增页面',
    search: '搜索',
    left: '左',
    right: '右',
    top: '上',
    bottom: '下',
    build: '构建',
    base: '基础UI',
    my: '我到UI',
    project: '项目UI',
    module: '模块',
    function: '功能',
    image: '图像',
    icon: '图标',
    chooseIcon: '选择图标',
    save: '保存',
    notsave: '未保存',
    saved: '已保存',
    ok: '确定',
    cancel: '取消',
    remove: '移除',
    close: '关闭',
    upload: '上传',
    selectFile: '选择文件',
    action: '行为',
    outline: '结构',
    style: '样式',
    effect: '效果',
    endpoint: '终端',
    device: '设备',
    deviceAuto: '自适应',
    devicePC: 'PC',
    deviceTablet: '平板设备',
    deviceLandscape: '横屏',
    devicePortrait: '手机',
    preview: '预览',
    discuss: '讨论',
    pleaseSelectUIItem: '请先选择UI',
    pleaseWait: '请稍后',
    align: '对齐',
    alignLeft: '居左',
    alignCenter: '居中',
    alignRight: '居右',
    choose: '选择...'
  },
  style: {
    horizontal: '水平',
    horizontalTip: '父容器需要制定类型',
    file: {
      accept: '文件类型',
      multiple: '多文件上传',
      acceptTip: '例如 image/*'
    },
    sizing: '尺寸',
    href: '地址',
    hr: {
      tip: '可通过布局面板设置高度，通过边框面板设置线条样式'
    },
    utilities: {
      shadow: '阴影',
      hShadow: '水平',
      vShadow: '垂直',
      blur: '模糊',
      spread: '大小',
      shadowType: '类型',
      opacity: '透明度'
    },
    nav: {
      type: '类型',
      fill: '填充',
      justified: '对齐'
    },
    form: {
      form: '表单',
      inline: '内联',
      inputName: '表单项名',
      required: '必填项',
      requiredDesc: '',
      placeholder: '提示文本',
      helpTip: '帮助文本',
      title: '标题',
      value: '值',
      valueTip: '值是必选项',
      text: '文本',
      multiple: '多选',
      taggable: '标签',
      searchable: '可搜索',
      size: '大小',
      row: '行',
      autoSize: '自动行高',
      state: '状态',
      stateNormal: '常规',
      stateDisabled: '禁用',
      stateReadonly: '只读',
      stateHidden: '隐藏',
      inputType: '类型',
      inputTypeText: '文本',
      inputTypeColor: '颜色',
      inputTypeDate: '日期',
      inputTypeEmail: '邮箱',
      inputTypeNumber: '数字',
      inputTypePassword: '密码',
      inputTypeURL: 'URL',
      default: '默认值',
      values: '值列表',
      addValue: '添加值',
      clearButton: '清空按钮',
      wordCount: '字数统计',
      autocomplete: '自动补全',
      maxLength: '最大字符数',
      min: '最小',
      max: '最大',
      step: '步进间隔'
    },
    card: {
      headless: '无头',
      footless: '无脚',
      drapTip: '请拖动组件到这里'
    },
    modal: {
      headless: '无头',
      footless: '无脚',
      drapTip: '请拖动组件到这里',
      esc: 'Esc 关闭',
      escTip: '按下Esc键关闭对话框',
      backdrop: '遮罩',
      backdropTip: 'Yes 有遮罩（点击可关闭）；No 无遮罩；Static有遮罩，并点击不能关闭',
      position: '位置'
    },
    table: {
      headless: '无头',
      header: '头部',
      footless: '无脚',
      footer: '脚部',
      accented: '特色',
      stripedRow: '斑马纹',
      hoverableRow: '悬浮标识',
      grid: '网格线',
      borderless: '无网格',
      border: '边框',
      bordered: '网格',
      normal: '常规',
      small: '紧凑',
      verticalAlignment: '垂直对齐',
      horizontalAlignment: '水平对齐',
      alignmentTop: '上',
      alignmentMiddle: '中',
      alignmentBottom: '下',
      alignmentLeft: '居左',
      alignmentCenter: '居中',
      alignmentRight: '居右',
      rounded: '圆角',
      data: '数据源',
      edit: '编辑数据',
      import: '导入 (csv, xls, xlsx)'
    },
    layout: {
      layout: '布局',
      overflow: '溢出',
      xOverflow: 'X 溢出',
      yOverflow: 'Y 溢出',
      grow: '拉升',
      shrink: '收缩',
      size: '固定大小',
      minSize: '最小尺寸',
      maxSize: '最大尺寸',
      width: '宽度',
      height: '高度',
      widthTip: 'px, rem, 或%',
      display: '显示',
      position: '位置',
      left: '左',
      top: '上',
      right: '右',
      bottom: '下',
      zIndex: 'z-index',
      float: '浮动',
      clearFloat: '清除浮动',
      offset: '位移',
      deviceSize: '设备尺寸',
      grid: '网格',
      gridTip: '设置网格和偏移时请注意设备尺寸',
      direction: '方向',
      justify: '左右对齐',
      alignItem: '行内上下对齐',
      alignContent: '多行上下对齐',
      wrap: '是否换行',
      css: 'CSS'
    },
    background: {
      backgroundForeground: '背景 & 前景',
      foreground: '前景',
      background: '背景',
      image: '图像',
      gradient: '渐变',
      addImage: '添加图像',
      blendMode: '混合模式',
      blend: {
        normal: 'Normal',
        multiply: 'Multiply',
        screen: 'Screen',
        overlay: 'Overlay',
        darken: 'Darken',
        lighten: 'Lighten',
        'color-dodge': 'Color Dodge',
        saturation: 'Saturation',
        color: 'Color',
        luminosity: 'Luminosity'
      },
      addGradient: '添加渐变',
      deleteImage: '删除图像',
      attachment: '固定',
      attachmentScroll: '本身固定',
      attachmentFixed: '窗口固定',
      attachmentLocal: '内容固定',
      size: '大小',
      sizeCover: 'Cover',
      sizeContain: 'Contain',
      sizeLength: '指定大小',
      repeat: '重复',
      repeatX: 'X轴重复',
      repeatY: 'Y轴重复',
      noRepeat: '不重复',
      clip: '剪裁',
      borderbox: '边框区域',
      paddingbox: '内边距区域',
      cententbox: '内容区域',
      origin: '相对位置'
    },
    button: {
      type: '类型',
      outline: '轮廓',
      linkHref: '链接地址',
      groupBtn: '按钮组'
    },
    container: {
      type: '类型'
    },
    text: {
      type: '类型',
      Heading: '标题',
      Paragraph: '段落',
      Normal: '常规',
      lineThrough: '删除线',
      bold: '粗体',
      underline: '下划线',
      italic: '斜体',
      weight: '字重',
      lineHeight: '行高',
      fontSize: '字体大小',
      letterSpacing: '字间距'
    },
    image: {
      src: '地址'
    },
    list: {
      flush: '齐平'
    },
    progress: {
      label: '标签',
      striped: '斑马纹',
      animatedStripes: '动态斑马纹'
    },
    dropdown: {
      direction: '方向',
      directionUp: '上',
      directionDown: '下',
      directionLeft: '左',
      directionRight: '右',
      menuAlign: '菜单对齐',
      menuAlignLeft: '左',
      menuAlignRight: '右',
      itemType: '类型',
      splitBtn: '分体按钮'
    },
    icon: {
      notChooseIconTip: '从样式面板选择',
      size: '大小'
    },
    backgroundColor: '背景色',
    backgroundImage: '背景图',
    color: '颜色',
    width: '宽度',
    value: '值',
    radius: '圆角',
    theme: '样式主题',
    radiusSize: '圆角大小',
    byValue: '按值',
    predefinedClass: '预定义',
    class: '样式',
    byClass: '按样式',
    byCustomClass: '按自定义样式',
    margin: '外边距',
    borderOutline: '边框 & 轮廓',
    border: '边框',
    borderColor: '边框色',
    borderStyle: '边框样式',
    outlineStyle: '轮廓样式',
    outlineColor: '轮廓颜色',
    outlineWidth: '轮廓宽度',
    padding: '内边距',
    rangeInput: {

    },
    'margin-left': '左外边距',
    'margin-right': '右外边距',
    'margin-top': '上外边距',
    'margin-bottom': '下外边距',
    'padding-left': '左内边距',
    'padding-right': '右内边距',
    'padding-top': '上内边距',
    'padding-bottom': '下内边距',
    'border-left': '左边框',
    'border-right': '右边框',
    'border-top': '上边框',
    'border-bottom': '下边框',
    'border-top-left-radius': '左上圆角',
    'border-top-right-radius': '右上圆角',
    'border-bottom-left-radius': '左下圆角',
    'border-bottom-right-radius': '右下圆角'
  },
  table: {
    canNotParseExcel: '解析Excel文件出错了'
  },
  project: {
    setting: '设置'
  },
  api: {
    manage: 'API管理'
  },
  action: {
    api: {
      name: 'API',
      arg: '参数',
      desc: '描述',
      entry: '入口',
      input: '输入',
      output: '输出',
      bindTo: '绑定给',
      addArg: '添加参数',
      notBindAnyApi: '未绑定API',
      bindToValue: '值',
      bindToHTML: 'HTML',
      bindToText: '文本',
      bindToIteration: '迭代',
      bindInput: '绑定输入',
      bindOutput: '绑定输出',
      type: '类型',
      method: '方法',
      uri: 'URI',
      add: '添加API',
      bindAPI: '绑定API',
      pushDataAPI: '数据提交API',
      pullDataAPI: '数据获取API',
      pushDataAPIDesc: '数据提交API用来提交数据给服务端, 由该页面的其他组件出发，当前组件绑定该api来负责提供某项数据',
      pullDataAPIDesc: '数据获取API用来从服务端获取数据，由上层组件触发，当前绑定该API的结果来显示某个数据',
      event: '事件',
      bindEvent: '绑定事件'
    }
  },
  event: {
    bind: '绑定事件',
    popupDesign: '设计',
    bindAPI: '绑定接口',
    notBindEvent: '未绑定事件',
    error: {
      notDefined: '未设置',
      bindEventEmpty: '请选择需要绑定的事件',
      bindAPIEmpty: '请选择需要帮的接口'
    },
    api: {
      popup: '弹窗',
      call: '调用接口'
    },
    'life cycle': '生命周期',
    interactive: '人机交互'
  }
}
