
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
import $ from 'jquery'
import InitUI from '@/components/Common'

export default {
  name: 'ElementPath',
  setup (props: any, context: any) {
    const store = useStore()
    const info = InitUI()
    const { hoverUIItemId, selectedUIItemId, selectedPageId } = info
    const selectedUIItem = computed(() => {
      if (!selectedUIItemId.value || selectedUIItemId.value === '') return null
      const { uiConfig } = store.getters.getUIItemInPage(selectedUIItemId.value, selectedPageId.value)
      return uiConfig
    })

    const updatePath = (item: Record<any, any>) => {
      paths.value.push(item)
      const { parentConfig } = store.getters.getUIItemInPage(item.meta.id, selectedPageId.value)
      if (parentConfig && parentConfig.type !== 'Unknown') {
        updatePath(parentConfig)
      }
    }

    const paths: any = ref([])
    watch(selectedUIItem, () => {
      paths.value.splice(0)
      if (selectedUIItem.value) {
        updatePath(selectedUIItem.value)
      }
    })

    const uiMouseEnter = (event: any) => {
      const el = $(event.target)
      hoverUIItemId.value = $(el).parents('.item').attr('data-uiid')
    }

    const uiMouseLeave = (event: any) => {
      hoverUIItemId.value = ''
    }

    const uiClick = (event: any) => {
      const item = $(event.target).parents('.item')
      selectedUIItemId.value = item.attr('data-uiid')

      const scrollbar = document.querySelector('.workspace-scrollbar')
      if (scrollbar) {
        const uidom = document.getElementById(selectedUIItem.value)
        if (uidom) {
          const rect = uidom.getBoundingClientRect()
          // 200 是一个估值，确保uidom在主窗体内，不被上边和左边的内容遮挡
          scrollbar.scrollTo(rect.x - 200, rect.y - 200)
        }
      }
    }

    const getContainerDisplay = function (node) {
      const css = node?.meta?.css?.container
      // console.log(node.meta?.css?.container)
      return css ? ` (${css}) ` : ''
    }

    return {
      paths,
      selectedPageId,
      getContainerDisplay,
      uiMouseEnter,
      uiMouseLeave,
      uiClick
    }
  }
}
