
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import ydhl from '@/lib/ydhl'
import { useI18n } from 'vue-i18n'
import { YDJSStatic } from '@/lib/ydjs'
import { useRouter } from 'vue-router'
import $ from 'jquery'
declare const YDJS: YDJSStatic

export default {
  name: 'ModuleFunction',
  setup (props: any, context: any) {
    const store = useStore()
    const currFunctionId = computed(() => store.state.design.function.id)
    const currModuleId = computed(() => store.state.design.module.id)
    const openModuleIds = ref({})
    const openFunctionIds = ref({})
    openModuleIds.value[currModuleId.value] = true
    openFunctionIds.value[currFunctionId.value] = true

    const currPageId = computed(() => store.state.design?.page?.meta?.id)
    const router = useRouter()
    const project = computed(() => store.state.design.project)
    const currPage = computed(() => store.state.design.page)
    const versionId = computed(() => store.state.design.versionId)
    const modules = ref<Array<any>>()
    const api = ydhl.api
    const refresh = (cb) => {
      // modules.value = []
      ydhl.get('api/module', { uuid: project.value.id }, (rst) => {
        if (rst && rst.success) {
          modules.value = rst.data
        }
        if (cb) cb()
      })
    }
    onMounted(() => {
      refresh(null)
    })
    watch(currPageId, () => {
      refresh(null)
    })
    const gotoPage = (pid) => {
      const dialogid = YDJS.loading(t('common.pleaseWait'))
      ydhl.savePage(currFunctionId.value, currPage.value, versionId.value, (rst) => {
        if (rst?.success) {
          store.commit('updateState', { saved: 1, versionId: rst.data.versionId })
        }
        router.push({
          path: '/',
          query: {
            uuid: pid
          }
        })

        YDJS.hide_dialog(dialogid)
      })
    }
    const toggleModule = (event, id) => {
      if (openModuleIds.value[id]) {
        delete openModuleIds.value[id]
      } else {
        openModuleIds.value[id] = true
      }
    }
    const toggleFunction = (event, func) => {
      if (func.pages.length === 0) {
        gotoFunction(func.id)
        return
      }
      if (openFunctionIds.value[func.id]) {
        delete openFunctionIds.value[func.id]
      } else {
        openFunctionIds.value[func.id] = true
      }
    }

    const promptConfirm = (item) => {
      YDJS.prompt(t('module.deleteModuleConfirm', [item.name]), '', 'input', (dialogId, value) => {
        ydhl.post(`module/${item.id}/delete.json`, { value: value }, [], function (rst) {
          if (!rst || !rst.success) {
            YDJS.toast(rst?.msg || 'Oops', YDJS.ICON_ERROR)
            return
          }
          YDJS.hide_dialog(dialogId)
          refresh(() => {
            store.commit('deleteModule', { moduleId: value })
            // 如果删除的是当前在编辑的module
            if (item.id === currModuleId.value && modules.value) {
              for (const m of modules.value) {
                if (m.functions && m.functions.length > 0) {
                  const f = m.functions.pop()
                  if (f.pages && f.pages.length > 0) {
                    for (const p of f.pages) {
                      window.location.href = '?uuid=' + p.id
                    }
                  }
                  return
                }
              }
              // 没有模块了跳转到后台
              window.location.href = api + `project/${project.value.id}/module`
            }
          })
        })
      })
    }
    const addModule = (project) => {
      const dialogid = YDJS.dialog(api + 'project/' + project.id + '/addmodule'
        , ''
        , t('module.addModule')
        , YDJS.SIZE_SMALL
        , YDJS.BACKDROP_STATIC
        , []
        , undefined
        , (rst) => {
          YDJS.hide_dialog(dialogid)
          refresh(null)
        })
    }
    const editModule = (item) => {
      const dialogid = YDJS.dialog(api + 'module/' + item.id + '/edit'
        , ''
        , t('common.edit')
        , YDJS.SIZE_SMALL
        , YDJS.BACKDROP_STATIC
        , []
        , undefined
        , (rst) => {
          YDJS.hide_dialog(dialogid)
          console.log(rst)
          if (!rst || !rst.success) {
            YDJS.toast(rst?.msg || t('common.operationFail'), YDJS.ICON_ERROR)
          }
          refresh(null)
        })
    }
    const addFunction = (item) => {
      const dialogid = YDJS.dialog(api + 'module/' + item.id + '/addfunction'
        , ''
        , t('module.addFunction')
        , YDJS.SIZE_SMALL
        , YDJS.BACKDROP_STATIC
        , []
        , undefined
        , (rst) => {
          YDJS.hide_dialog(dialogid)
          refresh(null)
        })
    }
    const wheel = (event, funcid) => {
      const panel = $('#' + funcid + 'panel')
      // console.log(event)
      // event.deltaY 滚动方向与距离
      const scrollLeft = panel.scrollLeft() || 0
      panel.scrollLeft((scrollLeft + event.deltaY))
    }
    const gotoFunction = (funcid: string) => {
      const dialogid = YDJS.loading(t('common.pleaseWait'))
      ydhl.savePage(currFunctionId.value, currPage.value, versionId.value, (rst) => {
        if (rst?.success) {
          store.commit('updateState', { saved: 1, versionId: rst.data.versionId })
        }
        router.push({
          path: '/',
          query: {
            functionId: funcid
          }
        })

        YDJS.hide_dialog(dialogid)
      })
    }
    const timestamp = ref(Date.parse((new Date()).toTimeString()))
    const { t } = useI18n()
    return {
      t,
      currFunctionId,
      currModuleId,
      project,
      modules,
      api,
      currPageId,
      openModuleIds,
      openFunctionIds,
      timestamp,
      wheel,
      gotoPage,
      promptConfirm,
      addModule,
      editModule,
      addFunction,
      toggleFunction,
      toggleModule,
      gotoFunction
    }
  }
}
