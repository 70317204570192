
import initUI from '@/components/Common'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  name: 'StyleLayout',
  setup (props: any, context: any) {
    const info = initUI()
    const selectedUIItem = info.selectedUIItem
    const { t } = useI18n()
    const store = useStore()

    const displays = ref(['inherit', 'block', 'inline-block', 'flex', 'inline-flex'])
    const positions = ref(['static', 'absolute', 'relative', 'inherit', 'fixed'])
    const directions = ref(['row', 'row-reverse', 'column', 'column-reverse'])
    const floats = ref(['left', 'right', 'none'])
    const clears = ref(['left', 'right', 'both', 'none'])
    const justifies = ref(['flex-start', 'center', 'flex-end', 'space-between', 'space-around'])
    const alignItems = ref(['flex-start', 'center', 'flex-end', 'baseline', 'stretch'])
    const alignContents = ref(['flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'stretch'])
    const wraps = ref(['nowrap', 'wrap', 'wrap-reverse'])
    const overflows = ref(['visible', 'hidden', 'scroll', 'auto'])

    const isContainer = computed(() => {
      return selectedUIItem.value.meta.isContainer
    })
    const parentUIItem = computed(() => {
      if (!info.selectedUIItemId.value) return null
      const { parentConfig } = store.getters.getUIItemInPage(info.selectedUIItemId.value, info.selectedPageId.value)
      return parentConfig
    })
    const currDisplay = computed<string>({
      get () {
        return info.getMeta('display', 'style') || 'inherit'
      },
      set (v) {
        info.setMeta('display', v, 'style')
      }
    })
    const currPosition = computed<string>({
      get () {
        return info.getMeta('position', 'style') || 'static'
      },
      set (v) {
        info.setMeta('position', v, 'style')
      }
    })
    const positionTop = computed<string>({
      get () {
        return info.getMeta('top', 'style') || ''
      },
      set (v) {
        info.setMeta('top', v, 'style')
      }
    })
    const positionRight = computed<string>({
      get () {
        return info.getMeta('right', 'style') || ''
      },
      set (v) {
        info.setMeta('right', v, 'style')
      }
    })
    const positionBottom = computed<string>({
      get () {
        return info.getMeta('bottom', 'style') || ''
      },
      set (v) {
        info.setMeta('bottom', v, 'style')
      }
    })
    const positionLeft = computed<string>({
      get () {
        return info.getMeta('left', 'style') || ''
      },
      set (v) {
        info.setMeta('left', v, 'style')
      }
    })
    const zindex = computed<string>({
      get () {
        return info.getMeta('z-index', 'style') || ''
      },
      set (v) {
        info.setMeta('z-index', v, 'style')
      }
    })
    const currWidth = computed<string>({
      get () {
        return info.getMeta('width', 'style') || ''
      },
      set (v) {
        info.setMeta('width', v, 'style')
      }
    })
    const currHeight = computed<string>({
      get () {
        return info.getMeta('height', 'style') || ''
      },
      set (v) {
        info.setMeta('height', v, 'style')
      }
    })
    const minWidth = computed<string>({
      get () {
        return info.getMeta('min-width', 'style') || ''
      },
      set (v) {
        info.setMeta('min-width', v, 'style')
      }
    })
    const minHeight = computed<string>({
      get () {
        return info.getMeta('min-height', 'style') || ''
      },
      set (v) {
        info.setMeta('min-height', v, 'style')
      }
    })
    const maxWidth = computed<string>({
      get () {
        return info.getMeta('max-width', 'style') || ''
      },
      set (v) {
        info.setMeta('max-width', v, 'style')
      }
    })
    const maxHeight = computed<string>({
      get () {
        return info.getMeta('max-height', 'style') || ''
      },
      set (v) {
        info.setMeta('max-height', v, 'style')
      }
    })
    const currDirection = computed({
      get () {
        return info.getMeta('flex-direction', 'style') || ''
      },
      set (v) {
        info.setMeta('flex-direction', v, 'style')
      }
    })
    const currFloat = computed({
      get () {
        return info.getMeta('float', 'style') || 'none'
      },
      set (v) {
        info.setMeta('float', v, 'style')
      }
    })
    const currClear = computed({
      get () {
        return info.getMeta('clear', 'style') || 'none'
      },
      set (v) {
        info.setMeta('clear', v, 'style')
      }
    })
    const currJustify = computed({
      get () {
        return info.getMeta('justify-content', 'style') || 'flex-start'
      },
      set (v) {
        info.setMeta('justify-content', v, 'style')
      }
    })
    const currShrink = computed({
      get () {
        return info.getMeta('flex-shrink', 'style') || ''
      },
      set (v) {
        info.setMeta('flex-shrink', v, 'style')
      }
    })
    const currGrow = computed({
      get () {
        return info.getMeta('flex-grow', 'style') || '0'
      },
      set (v) {
        info.setMeta('flex-grow', v, 'style')
      }
    })
    const currAlignItem = computed({
      get () {
        return info.getMeta('align-items', 'style') || 'stretch'
      },
      set (v) {
        info.setMeta('align-items', v, 'style')
      }
    })
    const currAlignContent = computed({
      get () {
        return info.getMeta('align-content', 'style') || 'stretch'
      },
      set (v) {
        info.setMeta('align-content', v, 'style')
      }
    })
    const currWrap = computed({
      get () {
        return info.getMeta('flex-wrap', 'style') || 'nowrap'
      },
      set (v) {
        info.setMeta('flex-wrap', v, 'style')
      }
    })
    const currOffset: any = {}
    for (const size in info.cssMap.value.deviceSize) {
      currOffset[size] = computed({
        get () {
          const offset = info.getMeta('offset', 'css') || {}
          return offset?.[size] || ''
        },
        set (v) {
          const offset = info.getMeta('offset', 'css') || {}
          offset[size] = v
          info.setMeta('offset', offset, 'css')
        }
      })
    }

    const currGridCol: any = {}
    for (const size in info.cssMap.value.deviceSize) {
      currGridCol[size] = computed({
        get () {
          const grid = info.getMeta('grid', 'css') || {}
          return grid?.[size] || ''
        },
        set (v) {
          const grid = info.getMeta('grid', 'css') || {}
          grid[size] = v
          info.setMeta('grid', grid, 'css')
        }
      })
    }

    const xOverflow = computed({
      get: () => {
        return info.getMeta('overflow-x', 'style') || 'visible'
      },
      set: (v) => {
        info.setMeta('overflow-x', v, 'style')
      }
    })

    const yOverflow = computed({
      get: () => {
        return info.getMeta('overflow-y', 'style') || 'visible'
      },
      set: (v) => {
        info.setMeta('overflow-y', v, 'style')
      }
    })
    const isFlex = computed(() => {
      return currDisplay.value !== undefined ? currDisplay.value.match(/flex|inline-flex/) : false
    })
    const isParentFlex = computed(() => {
      return parentUIItem.value?.meta?.style?.display !== undefined ? parentUIItem.value?.meta?.style?.display.match(/flex|inline-flex/) : false
    })
    const endKind = computed<string>(() => store.state.design.endKind)
    return {
      ...info,
      displays,
      positions,
      directions,
      floats,
      clears,
      justifies,
      alignItems,
      alignContents,
      wraps,
      currDisplay,
      currPosition,
      positionTop,
      positionRight,
      positionBottom,
      positionLeft,
      zindex,
      currDirection,
      currOffset,
      currGridCol,
      currWidth,
      currHeight,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      currFloat,
      currClear,
      currJustify,
      currAlignItem,
      currAlignContent,
      currWrap,
      currShrink,
      currGrow,
      t,
      isFlex,
      isParentFlex,
      isContainer,
      xOverflow,
      yOverflow,
      overflows,
      endKind
    }
  }
}
