import $ from 'jquery'
import { createPopper, Placement, VirtualElement } from '@popperjs/core'
import { nextTick } from 'vue'
import { YDJSStatic } from './ydjs'
declare const YDJS: YDJSStatic

export default {
  version: '1.0.16 build 220207',
  // api: 'http://ydecloud.local.com/',
  // socket: 'ws://localhost:8888',
  api: 'https://ydecloud.yidianhulian.com/',
  socket: 'wss://ydecloud.yidianhulian.com:8888',

  getRgbaInfo (str: string) {
    if (str.trim().match(/^#/)) {
      if (str.length === 4) { // #fff 短写格式
        const r = str.slice(1, 2)
        const g = str.slice(2, 3)
        const b = str.slice(3, 4)
        return {
          r: parseInt('0x' + r + r),
          g: parseInt('0x' + g + g),
          b: parseInt('0x' + b + b),
          a: 1
        }
      }
      const a = parseInt('0x' + str.slice(7, 9))
      return {
        r: parseInt('0x' + str.slice(1, 3)),
        g: parseInt('0x' + str.slice(3, 5)),
        b: parseInt('0x' + str.slice(5, 7)),
        a: !isNaN(a) ? a / 255 : 1
      }
    }

    const matches: any = str.match(/(?<r>[\d]+)\s*,\s*(?<g>[\d]+)\s*,\s*(?<b>[\d]+)\s*(,\s*(?<a>.+))?\)/)

    return {
      r: matches?.groups?.r,
      g: matches?.groups?.b,
      b: matches?.groups?.b,
      a: matches?.groups?.a !== undefined ? matches?.groups?.a : 1
    }
  },
  hex2rgba (hex: string) {
    const info = this.getRgbaInfo(hex)
    return 'rgba(' + info.r + ',' + info.g + ',' + info.b + ',' + info.a + ')'
  },
  rgba2hex (rgba: string) {
    const info: any = this.getRgbaInfo(rgba)
    if (!info?.r) return ''
    let hex =
        (info.r | 1 << 8).toString(16).slice(1) +
        (info.g | 1 << 8).toString(16).slice(1) +
        (info.b | 1 << 8).toString(16).slice(1)
    hex = hex + (((info?.a || 1) * 255) | 1 << 8).toString(16).slice(1)

    return hex
  },
  getLanguage () {
    const urlParams = new URLSearchParams(document.location.search)
    return (urlParams.get('lang') || navigator.languages?.[0].toLowerCase() || 'en').replace(/-/, '')
  },
  /**
   * 防抖处理
   * @param fn
   * @param delay
   * @return Function
   */
  debounce (fn, delay) {
    let timer
    return function () {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        fn()
      }, delay)
    }
  },
  saveJwt (jwt: string) {
    window.sessionStorage.setItem('jwt', jwt)
  },
  getJwt () {
    return window.sessionStorage.getItem('jwt')
  },
  deletePage (pageid, cb) {
    this.post('api/deletepage.json', { pageid }, [], (rst: any) => {
      if (cb) {
        cb(rst)
      }
      if (!rst || !rst.success) {
        YDJS.toast(rst?.msg || 'delete failed', YDJS.ICON_ERROR)
      }
    })
  },
  deletePopup (pageid, cb) {
    this.post('api/deletepage.json', { pageid, type: 'popup' }, [], (rst: any) => {
      if (cb) {
        cb(rst)
      }
      if (!rst || !rst.success) {
        YDJS.toast(rst?.msg || 'delete failed', YDJS.ICON_ERROR)
      }
    })
  },
  savePage (functionId: any, page: any, versionId, cb: any = null) {
    if (!page || !page?.meta?.id) {
      const rst = { success: false, msg: 'Page Not Exist' }
      if (cb) cb(rst)
      return
    }
    page = JSON.parse(JSON.stringify(page))
    var self = this
    // 删除不需要保存的内容, 页面只保存当前页面
    const needSave = { page, functionId, versionId }
    this.post('api/save.json', { data: JSON.stringify(needSave) }, [], (rst: any) => {
      if (cb) {
        cb(rst)
      }
      if (!rst || !rst.success) {
        YDJS.toast(rst?.msg || 'save failed', YDJS.ICON_ERROR)
      } else {
        const socket = new WebSocket(self.socket)
        socket.addEventListener('open', function (event) {
          socket.send(JSON.stringify({
            action: 'modifiedPage',
            pageid: page.meta.id,
            token: self.getJwt()
          }))
          socket.close()
        })
      }
    })
  },

  get (url: string, data = {}, cb: any, dataType = 'json') {
    $.ajax({
      headers: {
        token: this.getJwt()
      },
      url: this.api + url,
      data: data,
      crossDomain: true,
      success: (data: any) => cb(data),
      dataType
    })
  },
  /**
   * post请求
   * @param url 不包含域名部分
   * @param data
   * @param files
   * @param cb
   */
  post (url: string, data: Record<any, any> = {}, files: Record<string, any>, cb: any, dataType = 'json') {
    const fd: FormData = new FormData()
    for (const key in data) {
      fd.append(key, data[key])
    }
    for (const file in files) {
      fd.append(file, files[file])
    }

    $.ajax({
      headers: {
        token: this.getJwt()
      },
      method: 'post',
      processData: false,
      contentType: false,
      url: this.api + url,
      data: fd,
      crossDomain: true,
      success: (data: any) => cb(data),
      error: (data: any) => cb(data),
      dataType
    })
  },
  deepMerge (...objs: Array<Record<any, any>>) {
    const result = Object.create(null)
    objs.forEach(obj => {
      if (obj) {
        Object.keys(obj).forEach(key => {
          const val = obj[key]
          if (this.isPlainObject(val)) {
            // 递归
            if (this.isPlainObject(result[key])) {
              result[key] = this.deepMerge(result[key], val)
            } else {
              result[key] = this.deepMerge(val)
            }
          } else {
            result[key] = val
          }
        })
      }
    })
    // console.log(result)
    return result
  },
  isPlainObject (val: any) {
    return toString.call(val) === '[object Object]'
  },

  /**
   * 切换显示Popper弹出菜单
   *
   * @param trigger 打开关闭控制值 true打开 false关闭
   * @param openWhere 在那个位置打开
   * @param el 打开的dom
   * @param placement
   * @param offset
   */
  togglePopper: function (trigger: any, openWhere: Element | VirtualElement, el: any, placement: Placement = 'bottom-end', offset = [0, 10]) {
    const oldState = trigger.value
    trigger.value = !oldState
    if (!trigger.value) return
    nextTick(function () {
      createPopper(openWhere, el.value, {
        placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: offset
            }
          }
        ]
      })
    })
  },
  isEmptyString: function (str: string) {
    return str === null || str === ''
  },
  isEmptyObject: function (e: any) {
    // eslint-disable-next-line no-unreachable-loop
    for (const t in e) {
      return !1
    }
    return !0
  },

  formatFloat: function (f: any) {
    const v = (parseFloat(f) || 0).toFixed(2)
    if (v.match(/\.00/)) {
      return parseInt(v)
    } else {
      return parseFloat(v)
    }
  },

  /**
   *
   * @param {type} len 长度
   * @param {type} radix 进制
   * @returns {String}
   */
  uuid: (len: number, radix: number, prefix = '') => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    radix = radix || chars.length
    const uuid: any = []
    if (len) {
      // Compact form
      for (let i = 0; i < len; i++) { uuid[i] = chars[0 | Math.random() * radix] }
    } else {
      // rfc4122, version 4 form
      let r
      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
      uuid[14] = '4'
      // Fill in random data. At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (let i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16
          uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
        }
      }
    }

    return (prefix || '') + uuid.join('')
  }
}
