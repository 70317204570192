<template>
  <div class="p-5 text-muted">TODO：我自己的UI库</div>
</template>

<script>
export default {
  name: 'UIOfMy'
}
</script>

<style scoped>

</style>
