import { UIKind, UIType } from '@/store/model'

export interface UIDefine{
  type: UIType,
  isContainer?: boolean,
  kind: Array<UIKind>,
  subItemType?: Array<UIType>,
  /**
   * 是否是表单项
   */
  isInput?: boolean,
  /**
   * i18n的字符串
   */
  name: string,
}
/**
 * 定义支持的UI组件类型，所有前端效果库都需要实现这些类型
 * 这里的类型定义是基于store/model UIMeta进行
 */
const baseUIDefines: Record<UIType, UIDefine> = {
  Unknown: {
    type: 'Container',
    isContainer: true,
    kind: ['mobile', 'pc'],
    name: 'ui.container'
  },
  Container: {
    type: 'Container',
    isContainer: true,
    kind: ['mobile', 'pc'],
    name: 'ui.container'
  },
  Text: {
    type: 'Text',
    kind: ['mobile', 'pc'],
    name: 'ui.text'
  },
  Button: {
    type: 'Button',
    kind: ['mobile', 'pc'],
    name: 'ui.button'
  },
  ButtonGroup: {
    type: 'ButtonGroup',
    kind: ['mobile', 'pc'],
    isContainer: true,
    /**
     * 容器运行加入的子元素的type
     */
    subItemType: ['Button', 'Dropdown'],
    name: 'ui.buttonGroup'
  },
  Image: {
    type: 'Image',
    kind: ['mobile', 'pc'],
    name: 'ui.image'
  },
  List: {
    type: 'List',
    kind: ['mobile', 'pc'],
    name: 'ui.list'
  },
  Nav: {
    type: 'Nav',
    isContainer: true,
    kind: ['pc', 'mobile'],
    name: 'ui.nav'
  },
  Navbar: {
    type: 'Navbar',
    kind: ['pc', 'mobile'],
    isContainer: true,
    name: 'ui.navbar'
  },
  Hr: {
    type: 'Hr',
    kind: ['mobile', 'pc'],
    name: 'ui.hr'
  },
  Breadcrumb: {
    type: 'Breadcrumb',
    kind: ['mobile', 'pc'],
    name: 'ui.breadcrumb'
  },
  Card: {
    type: 'Card',
    kind: ['mobile', 'pc'],
    isContainer: true,
    name: 'ui.card'
  },
  Pagination: {
    type: 'Pagination',
    kind: ['pc'],
    name: 'ui.pagination'
  },
  Progress: {
    type: 'Progress',
    kind: ['mobile', 'pc'],
    name: 'ui.progress'
  },
  Dropdown: {
    type: 'Dropdown',
    kind: ['pc'],
    name: 'ui.dropdown'
  },
  Input: {
    type: 'Input',
    kind: ['mobile', 'pc'],
    isInput: true,
    name: 'ui.input'
  },
  Textarea: {
    type: 'Textarea',
    kind: ['mobile', 'pc'],
    isInput: true,
    name: 'ui.textarea'
  },
  Select: {
    type: 'Select',
    kind: ['mobile', 'pc'],
    isInput: true,
    name: 'ui.select'
  },
  Checkbox: {
    type: 'Checkbox',
    kind: ['mobile', 'pc'],
    isInput: true,
    name: 'ui.checkbox'
  },
  Radio: {
    type: 'Radio',
    kind: ['mobile', 'pc'],
    isInput: true,
    name: 'ui.radio'
  },
  File: {
    type: 'File',
    kind: ['mobile', 'pc'],
    isInput: true,
    name: 'ui.file'
  },
  RangeInput: {
    type: 'RangeInput',
    kind: ['mobile', 'pc'],
    isInput: true,
    name: 'ui.rangeInput'
  },
  Icon: {
    type: 'Icon',
    kind: ['mobile', 'pc'],
    name: 'ui.icon'
  },
  Table: {
    type: 'Table',
    kind: ['pc'],
    name: 'ui.table'
  },
  Modal: {
    type: 'Modal',
    kind: ['pc'],
    isContainer: true,
    name: 'ui.modal'
  },
  Page: {
    type: 'Page',
    kind: ['mobile', 'pc'],
    isContainer: true,
    name: 'ui.page'
  }
}

export default baseUIDefines

/**
 * UI变量输出的组件格式定义
 */
const uiDefines: Record<string, Array<UIType>> = {
  common: [
    'Container', 'Text', 'Button', 'Image'
  ],
  base: [
    'ButtonGroup', 'Card', 'Breadcrumb', 'Pagination',
    'Progress', 'Dropdown', 'List', 'Nav', 'Navbar', 'Hr', 'Icon', 'Table'
  ],
  form: [
    'Input', 'Textarea', 'Radio', 'Checkbox', 'Select', 'File', 'RangeInput'
  ]
}

export function getUIDefines (kind) {
  // console.log(kind)
  const _: any = {}
  for (const type in uiDefines) {
    _[type] = []
    for (const item of uiDefines[type]) {
      if (baseUIDefines[item].kind.indexOf(kind) === -1) continue
      _[type].push(baseUIDefines[item])
    }
  }
  return _
}
