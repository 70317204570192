
import { useI18n } from 'vue-i18n'
import UIInit from '@/components/Common.ts'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'MasterPage',
  setup () {
    const store = useStore()
    const pageCount = 3
    const itemWidth = 80
    const { t } = useI18n()
    const pageLeft: any = ref(0)
    const width = computed(() => store.state.design.leftSidebarWidth)

    watch(width, () => {
      // 计算剩余需要补的个数
      const itemCount = ~~(width.value / itemWidth)
      if (itemCount > pageCount) {
        pageLeft.value = 0
        return
      }
      pageLeft.value = itemCount * 2 - pageCount
    }, {
      immediate: true
    })

    const addPage = (type: string) => {
      store.commit('addPage', { pageType: type })
    }

    return {
      ...UIInit(),
      t,
      pageLeft,
      addPage
    }
  }
}
