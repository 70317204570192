export default {
  page: {
    pleaseChoosePopupType: 'Please choose popup type',
    toast: 'Toast',
    alert: 'Alert dialog',
    prompt: 'Prompt dialog',
    confirm: 'Confirm dialog',
    custom: 'Custom dialog',
    choosePage: 'Choose page',
    name: 'Page',
    setting: 'Setting',
    exportSetting: 'Export Setting',
    title: 'Title',
    loading: 'Loading',
    file: 'Page Name',
    fileTip: 'File Name when compile and saved in folder, exclude ext',
    url: 'URL',
    homePage: 'Home Page',
    urlTip: 'URL is the page mapped for router, start with /',
    blank: 'Blank Page',
    editPage: 'click to rename',
    copyPage: 'Copy this page',
    code: 'Export Code',
    deletePage: 'Move to Trash',
    deletePageConfirm: 'Page will move to Trash, you can put it back anytime',
    lockPage: 'Lock/Unlock this page'
  },
  ui: {
    common: 'Common Component',
    form: 'Form Component',
    navbar: 'Navbar',
    utilities: 'Utilities',
    page: 'Page',
    component: 'Component',
    base: 'Base Component',
    text: 'Text',
    nav: 'Navigation',
    hr: 'Hr',
    button: 'Button',
    buttonGroup: 'Button Group',
    buttonGroupEmptyTip: 'you can add {0} in ButtonGroup',
    addItemTypeError: 'you can not add {0} in {1}, just allow item type: {2}',
    select: 'Select',
    textarea: 'Textarea',
    breadcrumb: 'Breadcrumb',
    card: 'Card',
    modal: 'Modal',
    checkbox: 'Checkbox',
    image: 'Image',
    input: 'Input',
    rangeInput: 'Range Input',
    list: 'List',
    pagination: 'Pagination',
    progress: 'Progress',
    radio: 'Radio',
    dropdown: 'Dropdown',
    container: 'Container',
    file: 'File',
    framework: 'UI Framework',
    icon: 'Icon',
    table: 'Table'
  },
  module: {
    addFunction: 'Add Function',
    addModule: 'Add Module',
    deleteModule: 'Delete Module',
    deleteModuleConfirm: 'This action cannot be undone. You will lose this module\'s funciton, page, ui and api, please input the module name {0} to delete'
  },
  common: {
    operationFail: 'operation fail, please try again',
    event: 'Event',
    eventShow: 'Show Event',
    eventShowDesc: 'Show event info panel of ui bound',
    readonly: 'Readonly',
    socketNotConnect: 'Socket Disconnected',
    notSaveInfo: 'Your content not save, are you sure?',
    pageHasBeenDeletedByOtherUser: 'Page has been deleted by user {0}',
    pageHasBeenModifiedByOtherUser: 'Page has been modified by user {0}, page will be reload',
    somebodyComeIn: 'Other Users',
    somebodyComeInTip: 'please pay attention to avoid conflict when saving. When multiple people open the page, it\'s best to be edited by only one person',
    orCustom: 'Or Custom',
    snapshotting: 'Snapshotting',
    idIsInvalidate: 'ID is invalidate',
    dragtohere: 'Drag UI To Here',
    goBack: 'Go Back',
    none: 'None',
    edit: 'Edit',
    import: 'Import',
    page: 'Page',
    addPage: 'Add Page',
    search: 'Search',
    left: 'Left',
    right: 'Right',
    top: 'Top',
    bottom: 'Bottom',
    build: 'Build',
    base: 'Base UI',
    my: 'My UI',
    project: 'Project UI',
    module: 'Module',
    function: 'Function',
    image: 'Image',
    icon: 'Icon',
    chooseIcon: 'Choose Icon',
    save: 'Save',
    notsave: 'Not Save',
    saved: 'Saved',
    ok: 'OK',
    cancel: 'Cancel',
    remove: 'Remove',
    close: 'Close',
    upload: 'Upload',
    selectFile: 'Select File',
    action: 'Action',
    outline: 'Outline',
    style: 'Style',
    effect: 'Effect',
    endpoint: 'Endpoint',
    device: 'Device',
    deviceAuto: 'Auto',
    devicePC: 'PC',
    deviceTablet: 'Tablet',
    deviceLandscape: 'Landscape',
    devicePortrait: 'Portrait',
    preview: 'Preview',
    discuss: 'Discuss',
    pleaseSelectUIItem: 'Please select UI item first',
    pleaseWait: 'Please wait',
    align: 'Align',
    alignLeft: 'Left',
    alignCenter: 'Center',
    alignRight: 'Right',
    choose: 'Choose...'
  },
  style: {
    horizontal: 'Horizontal',
    horizontalTip: 'parent container need specify type info',
    sizing: 'Sizing',
    file: {
      accept: 'Accept',
      multiple: 'Multiple',
      acceptTip: 'Such as image/*'
    },
    href: 'Href',
    hr: {
      tip: 'You can set height by layout panel, set line style by border panel'
    },
    utilities: {
      shadow: 'Shadow',
      hShadow: 'h-shadow',
      vShadow: 'v-shadow',
      blur: 'Blur',
      spread: 'Spread',
      shadowType: 'Type',
      opacity: 'Opacity'
    },
    nav: {
      type: 'Type',
      fill: 'Fill',
      justified: 'Justified'
    },
    form: {
      form: 'Form',
      inline: 'Inline',
      inputName: 'Name',
      required: 'Required',
      requiredDesc: '',
      placeholder: 'Placeholder',
      helpTip: 'Help Tip',
      title: 'Title',
      value: 'Value',
      valueTip: 'Value is required',
      text: 'Text',
      multiple: 'Multiple',
      taggable: 'Taggable',
      searchable: 'Searchable',
      size: 'Size',
      row: 'Row',
      autoSize: 'Auto Size',
      state: 'State',
      stateNormal: 'Normal',
      stateDisabled: 'Disabled',
      stateReadonly: 'Readonly',
      stateHidden: 'Hidden',
      inputType: 'Type',
      inputTypeText: 'Text',
      inputTypeColor: 'Color',
      inputTypeDate: 'Date',
      inputTypeEmail: 'Email',
      inputTypeNumber: 'Number',
      inputTypePassword: 'Password',
      inputTypeURL: 'URL',
      default: 'Default',
      values: 'Values',
      addValue: 'Add Value',
      clearButton: 'Clear Button',
      wordCount: 'Word Count',
      autocomplete: 'Autocomplete',
      maxLength: 'Max Length',
      min: 'Min',
      max: 'Max',
      step: 'Step'
    },
    card: {
      headless: 'Headless',
      footless: 'Footless',
      drapTip: 'Please Drag component in here'
    },
    modal: {
      headless: 'Headless',
      footless: 'Footless',
      drapTip: 'Please Drag component in here',
      esc: 'Esc',
      escTip: 'Closes the modal when escape key is pressed',
      backdrop: 'Backdrop',
      backdropTip: 'Includes a modal-backdrop element. Alternatively, specify static for a backdrop which doesn\'t close the modal on click.',
      position: 'Position'
    },
    table: {
      headless: 'Headless',
      header: 'Header',
      footless: 'Footless',
      footer: 'Footer',
      accented: 'Accented',
      stripedRow: 'Striped Row',
      hoverableRow: 'Hoverable Row',
      grid: 'Grid Line',
      borderless: 'Borderless',
      border: 'Border',
      bordered: 'Bordered',
      normal: 'Normal',
      small: 'Small',
      verticalAlignment: 'Vertical Alignment',
      horizontalAlignment: 'Horizontal Alignment',
      alignmentTop: 'Top',
      alignmentMiddle: 'Middle',
      alignmentBottom: 'Bottom',
      alignmentLeft: 'Left',
      alignmentCenter: 'Center',
      alignmentRight: 'Right',
      rounded: 'Rounded',
      data: 'Data Source',
      edit: 'Edit Data',
      import: 'Import (csv, xls, xlsx)'
    },
    layout: {
      layout: 'Layout',
      overflow: 'Overflow',
      xOverflow: 'X Overflow',
      yOverflow: 'Y Overflow',
      grow: 'Grow',
      shrink: 'Shrink',
      size: 'Fixed Size',
      minSize: 'Min Size',
      maxSize: 'Max Size',
      width: 'Width',
      height: 'Height',
      widthTip: 'pixels or %',
      display: 'Display',
      position: 'Position',
      left: 'Left',
      top: 'Top',
      right: 'Right',
      bottom: 'Bottom',
      zIndex: 'z-index',
      float: 'Float',
      clearFloat: 'Clear float',
      offset: 'Offset',
      deviceSize: 'Device Size',
      grid: 'Grid',
      gridTip: 'Pay attention to the DEVICE SIZE when set grid and offset',
      direction: 'Direction',
      justify: 'Justify',
      alignItem: 'Align Item',
      alignContent: 'Align Content',
      wrap: 'Wrap',
      css: 'CSS'
    },
    background: {
      backgroundForeground: 'Background & Foreground',
      foreground: 'Foreground',
      background: 'Background',
      image: 'Image',
      gradient: 'Gradient',
      addImage: 'Add Background Image',
      blendMode: 'Blend Mode',
      blend: {
        normal: 'Normal',
        multiply: 'Multiply',
        screen: 'Screen',
        overlay: 'Overlay',
        darken: 'Darken',
        lighten: 'Lighten',
        'color-dodge': 'Color Dodge',
        saturation: 'Saturation',
        color: 'Color',
        luminosity: 'Luminosity'
      },
      addGradient: 'Add Gradient',
      deleteImage: 'Delete Image',
      attachment: 'Attachment',
      attachmentScroll: 'Scroll',
      attachmentFixed: 'Fixed',
      attachmentLocal: 'Local',
      size: 'Size',
      sizeCover: 'Cover',
      sizeContain: 'Contain',
      sizeLength: 'Length',
      repeat: 'Repeat',
      repeatX: 'Repeat X',
      repeatY: 'Repeat Y',
      noRepeat: 'No Repeat',
      clip: 'Clip',
      borderbox: 'Border Box',
      paddingbox: 'Padding Box',
      cententbox: 'centent Box',
      origin: 'Origin'
    },
    button: {
      type: 'Type',
      outline: 'Outline',
      linkHref: 'link Href',
      groupBtn: 'Group Button'
    },
    container: {
      type: 'Type'
    },
    text: {
      type: 'Type',
      Heading: 'Heading',
      Paragraph: 'Paragraph',
      Normal: 'Normal',
      lineThrough: 'Line through',
      bold: 'Bold',
      underline: 'underline',
      italic: 'Italic',
      weight: 'Weight',
      lineHeight: 'Line height',
      fontSize: 'Font size',
      letterSpacing: 'Letter Spacing'
    },
    image: {
      src: 'SRC'
    },
    list: {
      flush: 'Flush'
    },
    progress: {
      label: 'Label',
      striped: 'Striped',
      animatedStripes: 'Animated Stripes'
    },
    dropdown: {
      direction: 'Direction',
      directionUp: 'Up',
      directionDown: 'Down',
      directionLeft: 'Left',
      directionRight: 'Right',
      menuAlign: 'Menu alignment',
      menuAlignLeft: 'Left',
      menuAlignRight: 'Right',
      itemType: 'Type',
      splitBtn: 'Split Button'
    },
    icon: {
      notChooseIconTip: 'Please choose from style panel',
      size: 'Size'
    },
    backgroundColor: 'Background Color',
    backgroundImage: 'Background Image',
    color: 'Color',
    width: 'Width',
    value: 'Value',
    radius: 'Radius',
    theme: 'Theme',
    radiusSize: 'Radius Width',
    byValue: 'By value',
    predefinedClass: 'Predefined',
    class: 'Class',
    byClass: 'By Class',
    byCustomClass: 'By Custom Class',
    margin: 'Margin',
    borderOutline: 'Border & outline',
    border: 'Border',
    borderColor: 'Border Color',
    borderStyle: 'Border Style',
    outlineStyle: 'Outline Style',
    outlineColor: 'Outline Color',
    outlineWidth: 'Outline Width',
    padding: 'Padding',
    rangeInput: {

    },
    'margin-left': 'Margin Left',
    'margin-right': 'Margin Right',
    'margin-top': 'Margin Top',
    'margin-bottom': 'Margin Bottom',
    'padding-left': 'Padding Left',
    'padding-right': 'Padding Right',
    'padding-top': 'Padding Top',
    'padding-bottom': 'Padding Bottom',
    'border-left': 'Border Left',
    'border-right': 'Border Right',
    'border-top': 'Border Top',
    'border-bottom': 'Border Bottom',
    'border-top-left-radius': 'Top Left Radius',
    'border-top-right-radius': 'Top Right Radius',
    'border-bottom-left-radius': 'Bottom Left Radius',
    'border-bottom-right-radius': 'Bottom Right Radius'
  },
  table: {
    canNotParseExcel: 'Can not parse Excel'
  },
  project: {
    setting: 'Setting'
  },
  api: {
    manage: 'API Manage'
  },
  action: {
    api: {
      name: 'API',
      arg: 'Arg',
      desc: 'Desc',
      entry: 'Entry',
      input: 'Input',
      output: 'Output',
      bindTo: 'Bind As',
      addArg: 'Add Arg',
      notBindAnyApi: 'Not Bind Any Api',
      bindToValue: 'Value',
      bindToHTML: 'HTML',
      bindToText: 'Text',
      bindToIteration: 'Iteration',
      bindInput: 'Bind Input',
      bindOutput: 'Bind Output',
      type: 'Type',
      method: 'Method',
      uri: 'URI',
      add: 'Add API',
      bindAPI: 'Bind API',
      pushDataAPI: 'Push Data API',
      pullDataAPI: 'Pull Data API',
      pushDataAPIDesc: 'Push data API used to post data (or other method like PUT/DELETE) to server, trigger by other ui in the same page; bind this API to collect data to post ',
      pullDataAPIDesc: 'Pull data API used to load data from server, trigger by parents ui, bind this api result to show data',
      event: 'Event',
      bindEvent: 'Bind Event'
    }
  },
  event: {
    bind: 'Bind Event',
    popupDesign: 'Design',
    notDefined: 'Not Defined',
    bindAPI: 'Bind API',
    notBindEvent: 'Not bind event',
    error: {
      notDefined: 'Not Defined',
      bindEventEmpty: 'Please select bind event',
      bindAPIEmpty: 'Please select api to bind'
    },
    api: {
      popup: 'popup',
      call: 'Call API'
    },
    'life cycle': 'Life Cycle',
    interactive: 'Interactive'
  }
}
