
import StyleForm from '@/components/sidebar/style/Form.vue'
import initUI from '@/components/Common'
import StyleLyout from '@/components/sidebar/style/Layout.vue'
import StyleBackground from '@/components/sidebar/style/Background.vue'
// import StyleFont from '@/components/sidebar/style/Font.vue'
import { ref, computed, watch, defineAsyncComponent } from 'vue'
import $ from 'jquery'
import MarginPadding from '@/components/sidebar/style/MarginPadding.vue'
import StyleBorder from '@/components/sidebar/style/Border.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import StyleUtilities from '@/components/sidebar/style/Utilities.vue'
import ydhl from '@/lib/ydhl'
export default {
  name: 'UIStyle',
  components: { StyleLyout, StyleUtilities, MarginPadding, StyleBorder, StyleForm, StyleBackground },
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    const store = useStore()
    const tempId = ref<any>('')
    const idIsError = ref(false)
    const uiID = computed({
      get () {
        if (!info.selectedUIItem.value) return ''
        return info.selectedUIItem.value.meta.id
      },
      set (v) {
        if (!info.selectedUIItem.value) return
        tempId.value = v
      }
    })
    watch(info.selectedUIItemId, () => {
      idIsError.value = false
    })

    const updateID = () => {
      idIsError.value = false
      if (!info.selectedUIItem.value) return
      if (!$.trim(tempId.value)) {
        tempId.value = ydhl.uuid(16, 16, info.selectedUIItem.value.type)
      }
      if (tempId.value !== info.selectedUIItem.value.meta.id && store.getters.idHasExists(tempId.value)) {
        idIsError.value = true
        return
      }
      info.setMeta('id', tempId.value)
      info.selectedUIItemId.value = tempId.value
    }
    const itemStyle = computed(() => {
      // 这句判断的目的，只是为了让computed是响应式的，要不然下面defineAsyncComponent 中的promise不是响应式的
      // props改变后，uiComponentWrap不会刷新
      if (!info.selectedUIItem.value) return null
      return defineAsyncComponent(
        () => new Promise((resolve) => {
          let type = info.selectedUIItem.value.type
          if (type.toLowerCase() === 'buttongroup') type = 'Button'
          require([`@/components/sidebar/style/${type}.vue`], resolve)
        }))
    })
    const isNotPage = computed(() => {
      return info.selectedUIItem?.value?.type !== 'Page'
    })
    const hasBorder = computed(() => {
      return isNotPage.value && info.selectedUIItem?.value?.type !== 'Hr'
    })
    const hasLayout = computed(() => {
      if (info.selectedUIItem?.value?.type === 'Hr') return false
      if (info.selectedUIItem?.value?.type === 'Page') return false
      if (info.selectedUIItem?.value?.type === 'Modal') return false
      return true
    })
    const hasBackground = computed(() => {
      if (info.selectedUIItem?.value?.pageType === 'popup') return false
      return true
    })
    return {
      ...info,
      t,
      tempId,
      uiID,
      idIsError,
      itemStyle,
      updateID,
      isNotPage,
      hasBorder,
      hasLayout,
      hasBackground
    }
  }
}
