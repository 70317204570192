
import { computed, nextTick, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import initUI from '@/components/Common'

export default {
  name: 'StyleMarginPadding',
  setup (props: any, context: any) {
    const info = initUI()
    const selectedUIItem = info.selectedUIItem
    const { t } = useI18n()
    const hoverOnSide = ref('')
    const settingSide = ref('')
    const isOpenSetting = ref(false)

    const attrs = computed(() => {
      if (!selectedUIItem.value) return
      const _css: any = { margin: [], padding: [] }
      const _style: any = { margin: [], padding: [] }
      const _attr: any = { margin: '', padding: '' }

      const meta = selectedUIItem.value.meta
      // console.log(meta)

      if (meta.css) {
        for (const name in meta.css) {
          if (name.match(/margin/)) {
            _css.margin.push(meta.css[name])
          } else if (name.match(/padding/)) {
            _css.padding.push(meta.css[name])
          }
        }
      }
      if (meta.style) {
        for (const name in meta.style) {
          if (name.match(/margin/)) {
            _style.margin.push(name + ':' + meta.style[name])
          } else if (name.match(/padding/)) {
            _style.padding.push(name + ':' + meta.style[name])
          }
        }
      }

      if (_css.margin.length) {
        _attr.margin = `css: ${_css.margin.join(' ')}`
      }
      if (_style.margin.length) {
        _attr.margin += `<br/>style: ${_style.margin.join(';')}`
      }
      if (_css.padding.length) {
        _attr.padding = `css: ${_css.padding.join(' ')}`
      }
      if (_style.padding.length) {
        _attr.padding += `<br/>style: ${_style.padding.join(';')}`
      }
      return _attr
    })

    const marginAttrs = ref<string>('')
    const paddingAttrs = ref<string>('')

    watch(attrs, (attr) => {
      if (!attr) {
        marginAttrs.value = ''
        paddingAttrs.value = ''
        return
      }
      marginAttrs.value = attr.margin
      paddingAttrs.value = attr.padding
    }, { immediate: true })
    const sizeClass = computed<string>({
      get () {
        return info.getMeta(settingSide.value, 'css')
      },
      set (v) {
        info.setMeta(settingSide.value, v === 'inherit' ? undefined : v, 'css')
      }
    })
    const size = computed<string>({
      get () {
        return info.getMeta(settingSide.value, 'style')
      },
      set (v) {
        info.setMeta(settingSide.value, v || undefined, 'style')
      }
    })

    const rightBackdropVisible = ref(false)

    const openSetting = (type: string) => {
      isOpenSetting.value = true
      nextTick(() => {
        rightBackdropVisible.value = true
      })
      settingSide.value = type
    }
    const closeSetting = () => {
      isOpenSetting.value = false
      rightBackdropVisible.value = false
      settingSide.value = ''
    }
    const isNotPage = computed(() => {
      return selectedUIItem?.value?.type !== 'Page'
    })

    return {
      ...info,
      hoverOnSide,
      isOpenSetting,
      settingSide,
      sizeClass,
      size,
      marginAttrs,
      paddingAttrs,
      rightBackdropVisible,
      isNotPage,
      openSetting,
      closeSetting,
      t
    }
  }
}
