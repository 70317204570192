
import $ from 'jquery'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ydhl from '@/lib/ydhl'

export default {
  name: 'SSO',
  setup (props: any, context: any) {
    onMounted(() => {
      const route = useRoute()
      const router = useRouter()
      const token = route.query.token || ''
      const uuid = route.query.uuid || ''
      const functionid = route.query.functionid || ''
      if (!token) {
        window.location.href = ydhl.api
        return
      }
      const query: any = {}
      if (uuid) {
        query.uuid = uuid
      } else {
        query.functionId = functionid
      }
      $.post(`${ydhl.api}api/sso.json`, { token }, (rst) => {
        if (rst && rst.success) {
          ydhl.saveJwt(rst.data.token)
          router.replace({ path: '/', query })
        } else {
          window.location.href = ydhl.api
        }
      })
    })
  }
}
