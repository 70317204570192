
import initUI from '@/components/Common'
import ColorPicker from '@/components/common/ColorPicker.vue'
import Gradient from '@/components/common/Gradient.vue'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import BackgroundImage from '@/components/sidebar/style/BackgroundImage.vue'

export default {
  name: 'StyleBackground',
  components: { BackgroundImage, Gradient, ColorPicker },
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    const foregroundColor = computed({
      get () {
        return info.getMeta('color', 'style')
      },
      set (v) {
        info.setMeta('color', v, 'style')
      }
    })
    const foregroundCss = computed({
      get () {
        return info.getMeta('foregroundTheme', 'css')
      },
      set (v) {
        info.setMeta('foregroundTheme', v, 'css')
      }
    })
    const blendModes = ref<Array<string>>([
      'normal',
      'multiply',
      'screen',
      'overlay',
      'darken',
      'lighten',
      'color-dodge',
      'saturation',
      'color',
      'luminosity'
    ])
    const blendMode = computed({
      get () {
        return info.getMeta('background-blend-mode', 'style')
      },
      set (v) {
        info.setMeta('background-blend-mode', v, 'style')
      }
    })
    const backgroundCss = computed({
      get () {
        return info.getMeta('backgroundTheme', 'css')
      },
      set (v) {
        info.setMeta('backgroundTheme', v, 'css')
      }
    })
    const backgroundColor = computed({
      get () {
        return info.getMeta('background-color', 'style')
      },
      set (v) {
        info.setMeta('background-color', v, 'style')
      }
    })
    const backgroundImages = computed(() => {
      return info.getMeta('background-image', 'style') || []
    })
    const backgroundGradients = computed(() => {
      return info.getMeta('background-image', 'style') || []
    })

    const addImage = (index) => {
      info.setMeta('background-image', [''], 'style', true)
      // 对应的其他Background属性也对应的生成
      info.setMeta('background-size', ['auto'], 'style', true)
      info.setMeta('background-repeat', ['repeat'], 'style', true)
      info.setMeta('background-position', ['0% 0%'], 'style', true)
      info.setMeta('background-clip', ['border-box'], 'style', true)
      info.setMeta('background-origin', ['padding-box'], 'style', true)
      info.setMeta('background-attachment', ['scroll'], 'style', true)
    }

    return {
      t,
      ...info,
      foregroundColor,
      foregroundCss,
      backgroundCss,
      backgroundColor,
      backgroundImages,
      backgroundGradients,
      blendModes,
      blendMode,
      addImage
    }
  }
}
