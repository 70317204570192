import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-muted p-3 " }
const _hoisted_2 = {
  key: 0,
  class: "ui-sidebar-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("small", null, _toDisplayString($setup.t("ui.framework")) + ": " + _toDisplayString(_ctx.ui) + " " + _toDisplayString(_ctx.uiVersion), 1)
    ]),
    (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.uiDefineTypes, (type) => {
      return (_openBlock(), _createBlock("div", {
        class: "ui-sidebar",
        key: type
      }, [
        _createVNode("div", {
          class: "ui-sidebar-header",
          onClick: ($event: any) => ($setup.closedType[type] = !$setup.closedType[type])
        }, [
          _createVNode("i", {
            class: {'iconfont':true, ' icon-tree-open':!$setup.closedType[type], ' icon-tree-close':$setup.closedType[type]}
          }, null, 2),
          _createTextVNode(" " + _toDisplayString($setup.t('ui.' + type)), 1)
        ], 8, ["onClick"]),
        (!$setup.closedType[type])
          ? (_openBlock(), _createBlock("div", _hoisted_2, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.uiDefines[type], (item, index) => {
                return (_openBlock(), _createBlock("div", {
                  class: "btn btn-white ui-item",
                  draggable: "true",
                  "data-type": item.type,
                  key: index
                }, [
                  _createVNode("i", {
                    class: `iconfont icon-${item.type.toLowerCase()}`
                  }, null, 2),
                  _createTextVNode(_toDisplayString($setup.t(item.name)), 1)
                ], 8, ["data-type"]))
              }), 128)),
              (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.left[type], (index) => {
                return (_openBlock(), _createBlock("div", {
                  class: "ui-item-holder",
                  key: index
                }))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 64))
}