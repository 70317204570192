
import { computed, onMounted, ref, watch } from 'vue'
import ydhl from '@/lib/ydhl'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { YDJSStatic } from '@/lib/ydjs'
import $ from 'jquery'
declare const bootstrap: any
declare const YDJS: YDJSStatic
// eslint-disable-next-line camelcase
declare const yd_upload_render: Function

export default {
  name: 'Upload',
  emits: ['update:modelValue'],
  props: {
    width: String,
    type: {
      type: String,
      default: 'image'
    },
    height: String,
    /**
     * {id, url, name}
     */
    modelValue: Object
  },
  setup (props: any, context: any) {
    const store = useStore()
    const uploadBtn = ref()
    const { t } = useI18n()
    const mimeType = {
      image: 'image/*',
      excel: 'xls,xlsx'
    }
    const project = computed(() => store.state.design.project)
    const dropdownToggle = ref()
    const fileSearchWord = ref('')
    const page = ref(1)
    const isFileSelectorOpen = ref(false)
    watch(isFileSelectorOpen, function (v) {
      store.commit('updateState', { backdropVisible: v })
    })
    const files = ref<Array<Record<string, string>>>()
    const selectFile = ref()

    const style = computed(() => {
      let str = `width:${props.width};height:${props.height}`
      if (props.modelValue && props.type === 'image') {
        str += `;background-image:url(${props.modelValue.url});background-size: cover;background-repeat: no-repeat;background-position: center;`
      }
      return str
    })

    let loadingId = ''
    const imageAdded = () => {
      loadingId = YDJS.loading(t('common.pleaseWait'))
    }
    const imageUploaded = (up: any, files: Array<any>, response) => {
      YDJS.hide_dialog(loadingId)
      if (!response || !response.success) return
      // console.log(response)
      context.emit('update:modelValue', response.data)
    }
    onMounted(() => {
      const uploadApi = ydhl.api + 'api/' + project.value.id + '/upload.json'
      yd_upload_render(uploadBtn.value, uploadApi, mimeType[props.type], imageAdded, imageUploaded)
    })
    const select = (event, file: any) => {
      selectFile.value = file
      const parentEl = $(event.target).has('.file').length ? event.target : $(event.target).parents('.file')
      $('.file').removeClass('text-primary border-primary')
      $(parentEl).addClass('text-primary border-primary')
    }
    const searchFile = (currPage: number = 0) => {
      if (currPage > 0) {
        page.value = currPage
      } else {
        page.value = 1
      }
      files.value = []
      ydhl.get(`api/${project.value.id}/file`, { type: props.type, q: fileSearchWord.value, page: page.value }, (rst) => {
        if (!rst || rst.success) {
          return
        }
        files.value = rst.data
      }, 'json')
    }
    const openSelectFileDialog = () => {
      isFileSelectorOpen.value = true
      files.value = []

      const loadingId = YDJS.loading(t('page.loading'))
      ydhl.get(`api/${project.value.id}/file`, { type: props.type, q: fileSearchWord.value }, (rst) => {
        YDJS.hide_dialog(loadingId)
        // console.log(rst)
        files.value = rst?.data || []
        const myModalEl = document.getElementById('openFileDialog') as HTMLElement
        const myModal = new bootstrap.Modal(myModalEl, { backdrop: 'static', keyboard: false })
        myModal.show()
      }, 'json')
    }
    const hideFileDialog = () => {
      // console.log(selectFile.value)
      if (selectFile.value) context.emit('update:modelValue', selectFile.value)
      $('#openFileDialog').remove()
      isFileSelectorOpen.value = false
    }
    return {
      style,
      t,
      uploadBtn,
      dropdownToggle,
      isFileSelectorOpen,
      fileSearchWord,
      hideFileDialog,
      openSelectFileDialog,
      imageUploaded,
      imageAdded,
      searchFile,
      selectFile,
      files,
      select
    }
  }
}
