
import { computed } from 'vue'

export default {
  name: 'Position',
  emits: ['update:modelValue'],
  props: {
    width: Number,
    height: Number,
    modelValue: Array
  },
  setup (props: any, context: any) {
    const update = (x, y) => {
      context.emit('update:modelValue', [x, y])
    }
    const currValue = computed(() => {
      // console.log(props.modelValue.join('-'))
      return props.modelValue.join('-')
    })
    return {
      update,
      currValue
    }
  }
}
