import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pt-1 flex-grow-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UITreeWrap = _resolveComponent("UITreeWrap")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    ($setup.currPage)
      ? (_openBlock(), _createBlock(_component_UITreeWrap, {
          key: _ctx.index,
          pageid: $setup.currPage.meta.id,
          uiconfig: $setup.currPage,
          tab: 0
        }, null, 8, ["pageid", "uiconfig"]))
      : _createCommentVNode("", true)
  ]))
}