
import { useI18n } from 'vue-i18n'
import UIInit from '@/components/Common.ts'
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import uidrag from '@/lib/uidrag'
import { getUIDefines } from '@/components/ui/define.ts'

export default {
  name: 'UIOfCommon',
  setup () {
    const store = useStore()
    const endKind = computed(() => store.state.design.endKind)
    const uiDefines = getUIDefines(endKind.value)
    const uiDefineTypes = Object.keys(uiDefines)
    const itemWidth = 80
    const { t } = useI18n()
    const left = ref<Record<string, number>>({})
    const width = computed(() => store.state.design.leftSidebarWidth)
    const closedType = ref({})

    watch(width, () => {
      // 计算剩余需要补的个数
      const itemCount = ~~(width.value / itemWidth)
      for (const type of uiDefineTypes) {
        if (itemCount > uiDefines[type].length) {
          left.value[type] = 0
          continue
        }
        left.value[type] = itemCount - uiDefines[type].length % itemCount
      }
    }, {
      immediate: true
    })

    onMounted(() => {
      // 从元素边栏拖入某个页面中去
      uidrag({
        target: '.ui-sidebar .ui-item',
        dragend: () => {
          store.commit('clearDragoverState')
        }
      })
    })

    return {
      ...UIInit(),
      uiDefines,
      uiDefineTypes,
      t,
      left,
      closedType
    }
  }
}
