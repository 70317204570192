<template>
  <div class="p-5 text-muted">TODO：项目共享的UI库</div>
</template>

<script>
export default {
  name: 'UIOfProject'
}
</script>

<style scoped>

</style>
