
import { computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import initUI from '@/components/Common'
import ColorPicker from '@/components/common/ColorPicker.vue'

export default {
  name: 'StyleBorder',
  components: { ColorPicker },
  setup (props: any, context: any) {
    const info = initUI()
    const selectedUIItem = info.selectedUIItem
    const { t } = useI18n()
    const hoverOnSide = ref('')
    const settingSide = ref('')
    const isOpenSetting = ref(false)
    const isOpenRadiusSetting = ref(false)
    const mpbSettingDialog = ref()
    const rightBackdropVisible = ref(false)

    const attrs = computed(() => {
      const _css: any = []
      const _style: any = []
      const _attr: any = []
      const meta = selectedUIItem.value.meta
      const cssMap = info.cssMap.value
      // console.log(meta)

      if (meta.css) {
        for (const name in meta.css) {
          if (!name.match(/border/)) continue
          _css.push(cssMap?.[name]?.[meta.css[name]] || meta.css[name])
        }
      }
      if (meta.style) {
        for (const name in meta.style) {
          if (!meta.style[name] || !name.match(/border/)) continue
          _style.push(name + ':' + meta.style[name])
        }
      }

      if (_css.length) {
        _attr.push(`css: <br/>${_css.join(',')}`)
      }

      if (_style.length) {
        _attr.push(`style: <br/>${_style.join('<br/>')}`)
      }
      return _attr.length === 0 ? '' : _attr.join('<br/><br/>', _attr)
    })

    const getStyle = (name) => {
      const style = info.getMeta(name, 'style')
      return style || ''
    }
    const borderRoundSize = computed<string>({
      get () {
        return info.getMeta('border-radius', 'style') || ''
      },
      set (v) {
        info.setMeta('border-radius', v || undefined, 'style')
      }
    })

    const outlineWidth = computed<string>({
      get () {
        return getStyle('outline-width')
      },
      set (v) {
        info.setMeta('outline-width', v || undefined, 'style')
      }
    })
    const outlineColor = computed({
      get () {
        return getStyle('outline-color')
      },
      set (v) {
        info.setMeta('outline-color', v, 'style')
      }
    })
    const outlineStyle = computed({
      get () {
        return getStyle('outline-style')
      },
      set (v) {
        info.setMeta('outline-style', v, 'style')
      }
    })
    const size = computed<string>({
      get () {
        return getStyle(`${settingSide.value}-width`)
      },
      set (v) {
        info.setMeta(`${settingSide.value}-width`, v || undefined, 'style')
      }
    })
    const radius = computed<string>({
      get () {
        return getStyle(`${settingSide.value}`)
      },
      set (v) {
        info.setMeta(`${settingSide.value}`, v || undefined, 'style')
      }
    })
    const borderColor = computed({
      get () {
        return getStyle(`${settingSide.value}-color`)
      },
      set (v) {
        info.setMeta(`${settingSide.value}-color`, v || undefined, 'style')
      }
    })
    const borderColorClass = computed({
      get () {
        return (info.getMeta('borderColorClass', 'css') || '').replace(/border-/, '')
      },
      set (v) {
        info.setMeta('borderColorClass', v, 'css')
      }
    })
    const borderStyle = computed({
      get () {
        return getStyle(`${settingSide.value}-style`) || 'none'
      },
      set (v) {
        info.setMeta(`${settingSide.value}-style`, v || undefined, 'style')
      }
    })
    const openRadiusSetting = (type: string) => {
      isOpenSetting.value = false
      isOpenRadiusSetting.value = true
      nextTick(() => {
        rightBackdropVisible.value = true
      })
      settingSide.value = type
    }
    const openSetting = (type: string) => {
      isOpenRadiusSetting.value = false
      isOpenSetting.value = true
      nextTick(() => {
        rightBackdropVisible.value = true
      })
      settingSide.value = type
    }
    const closeSetting = () => {
      isOpenSetting.value = false
      isOpenRadiusSetting.value = false
      rightBackdropVisible.value = false
      settingSide.value = ''
    }

    return {
      ...info,
      hoverOnSide,
      isOpenSetting,
      isOpenRadiusSetting,
      mpbSettingDialog,
      settingSide,
      borderRoundSize,
      size,
      outlineWidth,
      outlineStyle,
      outlineColor,
      radius,
      borderStyle,
      attrs,
      borderColor,
      rightBackdropVisible,
      openRadiusSetting,
      openSetting,
      closeSetting,
      borderColorClass,
      t
    }
  }
}
