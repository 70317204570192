import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "full-backdrop"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopPanel = _resolveComponent("TopPanel")
  const _component_LeftPanel = _resolveComponent("LeftPanel")
  const _component_RightPanel = _resolveComponent("RightPanel")
  const _component_WorkspacePanel = _resolveComponent("WorkspacePanel")

  return ($setup.loaded)
    ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
        _createVNode(_component_TopPanel),
        _createVNode(_component_LeftPanel),
        _createVNode(_component_RightPanel),
        _createVNode(_component_WorkspacePanel, { ref: "workspace" }, null, 512),
        ($setup.backdropVisible)
          ? (_openBlock(), _createBlock("div", _hoisted_1))
          : _createCommentVNode("", true)
      ], 64))
    : _createCommentVNode("", true)
}