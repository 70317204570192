
import { computed, ref } from 'vue'

export default {
  props: {
    modelValue: String,
    css: String
  },
  emits: ['update:modelValue'],
  name: 'ColorPicker',
  setup (props: any, context: any) {
    const colorInput = ref()
    const myColor = computed({
      get () {
        return props.modelValue
      },
      set (v) {
        context.emit('update:modelValue', v)
      }
    })
    const colorStyle = computed(() => {
      if (myColor.value) {
        return `background-color:${myColor.value}`
      }
      const transparent = require('@/assets/image/transparent.svg')
      // console.log(localColor.value)
      return `background-image:url(${transparent});background-size: contain;`
    })
    const toggleColor = () => {
      colorInput.value.click()
    }
    return {
      myColor,
      colorStyle,
      colorInput,
      toggleColor
    }
  }
}
