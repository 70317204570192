
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import InitUITree from '@/components/Common'
import UITreeLoader from '@/components/sidebar/uitree/Loader.vue'
import $ from 'jquery'

export default {
  name: 'UITreeWrap',
  components: { UITreeLoader },
  props: {
    uiconfig: Object,
    tab: Number,
    pageid: String
  },
  setup (props: any, context: any) {
    const store = useStore()
    const { focusUIItem, selectedPageId } = InitUITree()
    const containerBody = ref()
    const isOpen = ref(false)
    isOpen.value = selectedPageId.value === props.pageid
    const isContainer = computed(() => props.uiconfig.meta.isContainer)
    const dragoverUIItemId = computed(() => store.state.design.dragoverUIItemId)
    const dragoverPlacement = computed(() => store.state.design.dragoverPlacement)
    const dragIsUp = computed(() => dragoverUIItemId.value === props.uiconfig.meta.id && (dragoverPlacement.value === 'left' || dragoverPlacement.value === 'top'))
    const dragIsDown = computed(() => dragoverUIItemId.value === props.uiconfig.meta.id && (dragoverPlacement.value === 'right' || dragoverPlacement.value === 'bottom'))
    const isInlineEdit = ref(false)

    const toggleContainer = () => {
      if ($(containerBody.value).hasClass('d-none')) {
        isOpen.value = true
        $(containerBody.value).removeClass('d-none')
      } else {
        isOpen.value = false
        $(containerBody.value).addClass('d-none')
      }
      return true
    }

    const onInlineEdit = (isInline) => {
      isInlineEdit.value = isInline
    }

    return {
      focusUIItem,
      selectedPageId,
      dragoverUIItemId,
      dragoverPlacement,
      dragIsUp,
      dragIsDown,
      isContainer,
      containerBody,
      isOpen,
      toggleContainer,
      onInlineEdit,
      isInlineEdit
    }
  }
}
