
import { computed } from 'vue'
import initUI from '@/components/Common'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  name: 'StyleForm',
  setup (props: any, context: any) {
    const info = initUI()
    const store = useStore()
    const title = computed({
      get: () => {
        return info.getMeta('title') || ''
      },
      set: (v) => {
        info.setMeta('title', v)
      }
    })
    const horizontal = computed({
      get: () => {
        const horizontal = info.getMeta('horizontal', 'form')
        if (horizontal === undefined) {
          return isMobile.value
        }
        return horizontal
      },
      set: (v) => {
        info.setMeta('horizontal', v, 'form')
      }
    })
    const required = computed({
      get: () => {
        return info.getMeta('required', 'form') || false
      },
      set: (v) => {
        info.setMeta('required', v, 'form')
      }
    })
    const horizontalCol = computed({
      get: () => {
        return info.getMeta('horizontalCol', 'form') || 2
      },
      set: (v) => {
        info.setMeta('horizontalCol', v, 'form')
      }
    })
    const formSize = computed({
      get: () => {
        return info.getMeta('formSizing', 'css') || ''
      },
      set: (v) => {
        info.setMeta('formSizing', v, 'css')
      }
    })
    const inputName = computed({
      get: () => {
        return info.getMeta('inputName', 'form') || ''
      },
      set: (v) => {
        info.setMeta('inputName', v, 'form')
      }
    })
    const singleValue = computed({
      get: () => {
        return info.getMeta('value') || ''
      },
      set: (v) => {
        info.setMeta('value', v)
      }
    })
    const placeholderValue = computed({
      get: () => {
        return info.getMeta('placeholder', 'form') || ''
      },
      set: (v) => {
        info.setMeta('placeholder', v, 'form')
      }
    })
    const helptipValue = computed({
      get: () => {
        return info.getMeta('helpTip', 'form') || ''
      },
      set: (v) => {
        info.setMeta('helpTip', v, 'form')
      }
    })
    const isSingleValue = computed(() => {
      return /Input|Textarea/ig.test(info.selectedUIItem.value.type)
    })
    const isMultiValue = computed(() => {
      return /Select|Radio|Checkbox/ig.test(info.selectedUIItem.value.type)
    })
    const state = computed({
      get () {
        return (info.getMeta('state', 'form') || 'NORMAL').toUpperCase()
      },
      set (state) {
        info.setMeta('state', state, 'form')
      }
    })

    const titleAlign = computed({
      get () {
        return info.getMeta('titleAlign') || 'left'
      },
      set (state) {
        info.setMeta('titleAlign', state)
      }
    })
    const hasPlaceholder = computed(() => {
      return info.selectedUIItem.value?.type === 'Input' || info.selectedUIItem.value?.type === 'Textarea'
    })
    const isMobile = computed(() => store.state.design.endKind === 'mobile')
    const { t } = useI18n()
    return {
      t,
      title,
      horizontal,
      horizontalCol,
      formSize,
      required,
      inputName,
      isSingleValue,
      isMultiValue,
      singleValue,
      placeholderValue,
      helptipValue,
      state,
      titleAlign,
      hasPlaceholder,
      isMobile,
      ...info
    }
  }
}
