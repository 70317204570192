<template>
  <div class="p-5 text-muted">TODO：我的图片资源</div>
</template>

<script>
export default {
  name: 'Image'
}
</script>

<style scoped>

</style>
