
import { useI18n } from 'vue-i18n'
import initUI from '@/components/Common'
import eventMap from '@/store/events'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import ydhl from '@/lib/ydhl'
import { YDJSStatic } from '@/lib/ydjs'
import { UIAction, UIActionPopup, UIActionType, UIEventBinding } from '@/store/model'
import { useRouter } from 'vue-router'
declare const YDJS: YDJSStatic

/**
 * 行为绑定
 */
export default {
  name: 'BindEvent',
  components: {
  },
  setup (props: any, context: any) {
    const { t } = useI18n()
    const info = initUI()
    const store = useStore()
    const currFunctionId = computed(() => store.state.design.function.id)
    const currPage = computed(() => store.state.design.page)
    const versionId = computed(() => store.state.design.versionId)
    const project = computed(() => store.state.design.project)
    const router = useRouter()
    const allEvents = eventMap
    const imgSite = ydhl.api
    const allAPIs = [
      'popup',
      'call'
    ]

    const eventTypes: any = []
    /**
     * 事件及其所属分类
     */
    const eventOfType = {}
    for (const eventType in eventMap) {
      eventTypes.push(eventType)
      for (const event of eventMap[eventType]) {
        eventOfType[event] = eventType
      }
    }

    const bindedEvents = computed(() => {
      const _data = {}
      for (const eventType of eventTypes) {
        _data[eventType] = []
      }
      const events = info.selectedUIItem.value?.events

      if (!events) {
        return _data
      }
      for (const eventName in events) {
        const type = eventOfType[eventName]
        if (!_data[type]) _data[type] = []
        _data[type].push({ name: eventName, api: events[eventName] })
      }
      console.log(_data)
      return _data
    })

    // console.log(events)
    const addEventDialogVisible = ref(false)
    const bindEvent = ref('')
    const bindAPI = ref('')
    const openDialog = () => {
      addEventDialogVisible.value = true
      store.commit('updateState', { backdropVisible: true })
    }
    const closeDialog = () => {
      addEventDialogVisible.value = false
      store.commit('updateState', { backdropVisible: false })
    }
    const removeBind = (eventName, bindId) => {
      store.commit('removeEventBind', {
        itemid: info.selectedUIItemId.value,
        eventName,
        bindId
      })
    }
    const doBindEvent = () => {
      if (!bindEvent.value) {
        YDJS.toast(t('event.error.bindEventEmpty'), YDJS.ICON_ERROR)
        return
      }
      if (!bindAPI.value) {
        YDJS.toast(t('event.error.bindAPIEmpty'), YDJS.ICON_ERROR)
        return
      }
      const bindId = ydhl.uuid(6, 16, 'EB')
      const action: UIAction = {
        type: bindAPI.value as UIActionType
      }
      const bind = {
        action: action,
        id: bindId
      }
      store.commit('addEventBind', {
        itemid: info.selectedUIItemId.value,
        eventName: bindEvent.value,
        bindInfo: bind
      })
      closeDialog()
    }
    const designPopup = (eventName, api: UIEventBinding) => {
      const action = api.action as UIActionPopup
      // 已经有了对话框页面
      if (action.openedPageId) {
        const dialogid = YDJS.loading(t('common.pleaseWait'))
        ydhl.savePage(currFunctionId.value, currPage.value, versionId.value, (rst) => {
          if (rst?.success) {
            store.commit('updateState', { saved: 1, versionId: rst.data.versionId })
          }
          router.push({
            path: '/',
            query: {
              uuid: action.openedPageId,
              fromPageId: currPage.value.meta.id
            }
          })

          YDJS.hide_dialog(dialogid)
        })
      }
    }
    const choosePopup = (eventName, api: UIEventBinding) => {
      // 创建新对话框页面
      store.commit('createPopupBind', {
        itemid: info.selectedUIItemId.value,
        eventName,
        bindid: api.id
      })
    }
    const choosePage = (eventName, api: UIEventBinding) => {
      const dialogid = YDJS.dialog(ydhl.api + 'project/' + project.value.id + '/choosepage'
        , ''
        , t('page.choosePage')
        , YDJS.SIZE_FULL
        , YDJS.BACKDROP_STATIC
        , []
        , undefined
        , (rst) => {
          YDJS.hide_dialog(dialogid)
          if (!rst || !rst.success) {
            YDJS.toast(rst?.msg || t('common.operationFail'), YDJS.ICON_ERROR)
            return
          }
          // console.log(rst)
          if (!rst?.pageid) return
          // 创建新对话框页面
          store.commit('createPopupBind', {
            itemid: info.selectedUIItemId.value,
            eventName,
            bindid: api.id,
            newPageId: rst.pageid
          })
        })
    }
    return {
      t,
      ...info,
      addEventDialogVisible,
      allEvents,
      allAPIs,
      bindEvent,
      bindAPI,
      bindedEvents,
      imgSite,
      openDialog,
      closeDialog,
      doBindEvent,
      removeBind,
      choosePage,
      designPopup,
      choosePopup
    }
  }
}
