export default {
  'life cycle': [
    'onLoad',
    'onReady',
    'onShow',
    'onHide',
    'onBeforeUnload',
    'onUnload',
    'onResize',
    'onScroll'
  ],
  interactive: [
    'onClick',
    'onDblClick',
    'onPullDown',
    'onReachBottom'
  ]
}
