
import SidebarMasterPage from '@/components/sidebar/MasterPage.vue'
import SidebarModuleFunction from '@/components/sidebar/ModuleFunction.vue'
import SidebarUIOfMy from '@/components/sidebar/UIOfMy.vue'
import SidebarUIOfProject from '@/components/sidebar/UIOfProject.vue'
import SidebarUIOfCommon from '@/components/sidebar/UIOfCommon.vue'
import SidebarUploadUI from '@/components/sidebar/UploadUI.vue'
import SidebarImage from '@/components/sidebar/Image.vue'
import SidebarIcon from '@/components/sidebar/Icon.vue'
import split from '@/lib/split'
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import ydhl from '@/lib/ydhl'
export default {
  name: 'LeftPanel',
  components: {
    SidebarUploadUI,
    SidebarUIOfCommon,
    SidebarUIOfProject,
    SidebarModuleFunction,
    SidebarUIOfMy,
    SidebarMasterPage,
    SidebarImage,
    SidebarIcon
  },
  setup (props: any, context: any) {
    const splitStartWidth = ref(0)
    const store = useStore()

    const leftSidebarMinWidth = computed(() => store.state.design.leftSidebarMinWidth)
    const leftSidebarWidth = computed({
      get () {
        return store.state.design.leftSidebarWidth
      },
      set (v: number) {
        store.commit('updateState', { leftSidebarWidth: v })
      }
    })

    const sideBars = computed(() => store.state.design.leftSidebars)
    const leftSidebarStyle = computed((ctx: any) => `width:${leftSidebarWidth.value}px`)
    const currSidebar = computed({
      get () {
        const stacks: any = sideBars.value
        if (!stacks || stacks.length === 0) return ''
        return stacks[stacks.length - 1].sidebar
      },
      set (v: string) {
        store.commit('updateState', { leftSidebars: v !== '' ? [{ sidebar: v }] : [] })
      }
    })

    const onmouted = async () => {
      const splitSelector = '#left-sidebar .split'
      split(splitSelector, () => {
        splitStartWidth.value = leftSidebarWidth.value
        return {
          spliting: (dist: number) => {
            // console.log(dist)
            // if (target !== splitSelector) return
            if (leftSidebarWidth.value < leftSidebarMinWidth.value) {
              leftSidebarWidth.value = leftSidebarMinWidth.value
              return false
            }
            if (leftSidebarWidth.value > leftSidebarMinWidth.value * 2) {
              leftSidebarWidth.value = leftSidebarMinWidth.value * 2
              return false
            }
            leftSidebarWidth.value = splitStartWidth.value + dist // 往左dist是负数，但宽度是减少
            // console.log(dist + ',' + this.splitStartWidth)
            return true
          }
        }
      })
    }
    onMounted(onmouted)

    const { t } = useI18n()
    const version = computed(() => ydhl.version)
    return {
      t,
      splitStartWidth,
      leftSidebarStyle,
      currSidebar,
      version
    }
  }
}
