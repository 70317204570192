
/* eslint-disable */
import { computed, onMounted, onUnmounted, onUpdated } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import $ from 'jquery'
import UIBase from '@/components/ui/UIBase.vue'
import keyevent from '@/lib/keyevent'
import uidrag from '@/lib/uidrag'
import baseUIDefines from '@/components/ui/define'
import ydhl from '@/lib/ydhl'
import UIEventBadge from '@/components/ui/UIEventBadge.vue'
// import { YDJSStatic } from '@/lib/ydjs'
// declare const YDJS: YDJSStatic
declare const ports: any

export default {
  name: 'Page',
  components: { UIEventBadge, UIBase },
  setup (props: any, context: any) {
    const store = useStore()
    const route = useRoute()
    const currPage = computed(() => store.state.page.uiconfig)
    const project = computed(() => store.state.page.project)
    const isPopup = computed(() => currPage.value.pageType === 'popup')
    const isEmptyPopup = computed( () => {
      return isPopup.value && (!currPage.value.items || currPage.value.items.length===0)
    })
    const inlineEditItemId = computed(() => store.state.page.inlineEditItemId)
    const selectedUIItemId = computed(() => store.state.page.selectedUIItemId)
    const hoverUIItemId = computed(() => store.state.page.hoverUIItemId)
    const dragoverUIItemId = computed(() => store.state.page.dragoverUIItemId)
    const dragoverPlacement = computed(() => store.state.page.dragoverPlacement)
    const dragoverInParent = computed(() => store.state.page.dragoverInParent)
    const selectedPageId = computed(() => store.state.page.uiconfig?.meta?.id)
    const showEventPanel = computed(() => store.state.page.showEventPanel)
    let checkUpdated

    const deleteItem = () => {
      // 如果当前处于某个元素双击后的内部编辑状态
      // console.log($(event.target))
      if (inlineEditItemId.value || !selectedUIItemId.value) return
      // key事件是绑定到document上的，event.target是document，当某些能响应事件的元素，比如按钮，event.target是自己
      // if (!selectedUIItemId.value || ($(event.target).find('#' + selectedUIItemId.value).length === 0 && $(event.target).attr('id') !== selectedUIItemId.value)) return
      // console.log('test')
      postMessage({ type: 'deleteItem', data: { ids: [selectedUIItemId.value] }, pageId: selectedPageId.value })
    }

    const copyItem = () => {
      if (inlineEditItemId.value || !selectedUIItemId.value) return
      postMessage({ type: 'copyItem', data: { ids: [selectedUIItemId.value] }, pageId: selectedPageId.value })
    }

    const cutItem = () => {
      if (inlineEditItemId.value || !selectedUIItemId.value) return
      postMessage({ type: 'cutItem', data: { ids: [selectedUIItemId.value] }, pageId: selectedPageId.value })
    }

    const pasteItem = () => {
      if (inlineEditItemId.value || !selectedUIItemId.value) return
      postMessage({ type: 'pasteItem', data: { ids: [selectedUIItemId.value] }, pageId: selectedPageId.value })
    }

    const postMessage = (msg) => {
      // 这时ports是WorkspacePanel中的onMessage回调
      if (ports.parent) ports.parent(msg)
    }
    const onMessage = (data) => {
      // console.log('on page message', data)
      // console.log(event)
      if (!data) return
      if (data.type === 'state') {
        store.replaceState(data.state)
        // console.log(store.state)
        const project = data.state.page.project

        $(window.document.head).append(`<link href="${ydhl.api}vendor/uibuilder.css" rel="stylesheet">`)
        $(window.document.head).append(`<link href="${ydhl.api}vendor/${project.ui}@${project.ui_version}/index.css" rel="stylesheet">`)
        $(window.document.head).append(`<link href="${ydhl.api}upload/project/${project.id}/iconfont/iconfont.css" rel="stylesheet">`)
      } else if (data.type === 'updatePageState' || data.type === 'clearDragoverState' || data.type === 'switchEventShow') {
        store.commit(data.type, data.payload)
      } else if (data.type === 'deleteItem') {
        deleteItem()
      } else if (data.type === 'copyItem') {
        copyItem()
      } else if (data.type === 'cutItem') {
        cutItem()
      } else if (data.type === 'pasteItem') {
        pasteItem()
      }
    }

    const uiMouseEnter = (event: any) => {
      let el = $(event.target)
      if (!el.hasClass('ui')) el = el.parents('.ui')
      if (el.length === 0) return

      postMessage({ type: 'update', data: { hoverUIItemId: el.attr('id') } })
      el.addClass('hover')
    }
    const uiMouseLeave = (event: any) => {
      let el = $(event.target)
      if (!el.hasClass('ui')) el = el.parents('.ui')
      if (el.length === 0) return

      postMessage({ type: 'update', data: { hoverUIItemId: '' } })
      el.removeClass('hover')
    }
    const uiClick = (event: any) => {
      let el = $(event.target)
      if (!el.hasClass('ui')) el = el.parents('.ui')
      if (el.length === 0) {
        postMessage({ type: 'update', data: { selectedUIItemId: '' } })
        return
      }
      postMessage({ type: 'update', data: { selectedUIItemId: el.attr('id') } })
    }

    onMounted(() => {
      //该页面是效果展示页面，但是和uibuilder共同使用index.html，所以要把uibuilder使用的相关css和script移出
      const needRemoveds = $('[data-use="uibuilder"]')
      for (const needRemoved of needRemoveds) {
        $(needRemoved).remove()
      }
      // 在WorkspacePanel 上注册自己的事件回调
      const parentWindow: any = parent
      parentWindow.ports[route.query.uuid as string] = onMessage
      // 通知workspace自己准备好了，让其把事件回调向自己注册
      parent.postMessage({ type: 'loaded', pageId: route.query.uuid }, document.location.origin)

      $('body').on('mouseover', uiMouseEnter)
      $('body').on('mouseout', uiMouseLeave)
      $('body').on('click', uiClick)

      const {userAgent} = navigator
      const isMac = userAgent.includes('Macintosh')
      const modKey = (isMac ? 'meta' : 'ctrl') // ⌘
      keyevent.keydown('backspace', (keyEvent) => {
        // console.log('page backspace')
        if (inlineEditItemId.value || !selectedUIItemId.value) return
        keyEvent.preventDefault()
        keyEvent.cancelBubble = true // IE
        deleteItem()
      })
      keyevent.keydown([modKey, 'c'], (keyEvent) => {
        keyEvent.preventDefault()
        keyEvent.cancelBubble = true // IE
        copyItem()
      })
      keyevent.keydown([modKey, 'x'], (keyEvent) => {
        keyEvent.preventDefault()
        keyEvent.cancelBubble = true // IE
        cutItem()
      })
      keyevent.keydown([modKey, 'v'], (keyEvent) => {
        keyEvent.preventDefault()
        keyEvent.cancelBubble = true // IE
        pasteItem()
      })

      uidrag({
        target: '.ui,.subui',
        dragend: () => {
          // console.log('workspace dragend')
          postMessage({ type: 'update', data: { dragoverUIItemId: '', dragoverPlacement: '', dragoverInParent: '' } })
        },
        enter: (uiDragFromWhere, target: Element) => {
          if (target.classList.contains('uicontiner')) {
            return
          }
          // 当可拖动的元素进入可放置的目标，高亮目标节点
          if (target.classList.contains('ui')) {
            target.classList.add('dragover-ui')
          }
        },
        leave: (uiDragFromWhere, target: Element) => {
          if (target.classList.contains('ui')) {
            target.classList.remove('dragover-ui')
          }
        },
        /**
         * 悬浮在某个元素上面时，根据容器和元素的display属性，显示摆放提示符，提示
         * 当前拖动的元素应该可以放在悬浮元素的那一边：
         * 如果容器是flex column的，那么就只能放在上或者下
         * 其他容器时如果悬浮元素是block的（块元素）也只能放上和下，inline的流模型的，就只能放左右
         *
         * 悬浮在自己之上则忽略
         */
        over: (uiDragFromWhere, reference: any, dragOverPosi: Record<string, boolean>, isContainer: boolean, placement: string, uidragged, placeInParent) => {
          // console.log('page over')
          const uIItemId: any = $(reference).attr('id')
          // Tree拖入时不能拖入自己的子元素中
          if (uiDragFromWhere === 'uiTree') {
            const sourceId = $(uidragged).attr('data-uiid')
            if ($(`[data-uiid='${uIItemId}']`).parents(`[data-uiid='${sourceId}']`).length > 0) {
              return
            }
          }
          if (dragoverUIItemId.value === uIItemId && dragoverPlacement.value === placement && placeInParent == dragoverInParent.value) return
          // console.log('over postMessage ' + placement + '，' + placeInParent)
          postMessage({ type: 'update', data: { dragoverUIItemId: uIItemId, dragoverPlacement: placement, dragoverInParent: placeInParent } })
        },
        /**
         * drop处理
         * @param sourceEl 被拖动的元素
         * @param targetEl 目标元素
         */
        drop: (uiDragFromWhere, sourceEl: Element, targetEl: Element, placeInParent) => {
          // console.log(sourceEl)
          let sourceId = $(sourceEl).attr('id')
          const sourcePageId = $(sourceEl).attr('data-pageid')
          const targetId = $(targetEl).attr('id')
          const targetPageId = $(targetEl).attr('data-pageid')
          // 从组件边栏拖入
          if (uiDragFromWhere === 'uiItem') {
            const type: any = $(sourceEl).attr('data-type')
            // console.log({ sourceId, sourcePageId, targetId, targetPageId })
            postMessage({
              type: 'addItem',
              data: {
                type,
                meta: {
                  id: ydhl.uuid(10, 16, type),
                  title: type,
                  form: baseUIDefines[type]?.isInput ? {} : undefined,
                  isContainer: baseUIDefines[type]?.isContainer || false
                },
                placeInParent,
                pageId: targetPageId,
                placement: dragoverPlacement.value,
                targetId
              }
            })
            return
          }
          // 从outline拖入
          if (uiDragFromWhere === 'uiTree') {
            sourceId = $(sourceEl).attr('data-uiid')
            // 不能拖入自己的子元素中
            if ($(`#${targetId}`).parents(`#${sourceId}`).length > 0) {
              postMessage({ type: 'update', data: { dragoverUIItemId: '', dragoverPlacement: '', dragoverInParent: '' } })
              return
            }
          }

          postMessage({ type: 'moveItem', data: { sourceId, sourcePageId, targetId, targetPageId, placeInParent } })
        }
      })
    })

    onUpdated(() => {
      // console.log('onUpdated')
      if (checkUpdated) clearInterval(checkUpdated)
      checkUpdated = setInterval(() => {
        const clientHeight = document.querySelector(`#${currPage.value.meta.id}`)?.clientHeight || 0
        if (clientHeight > 50 && currPage.value) {
          // console.log(currPage.value.meta.id + ':' + clientHeight)
          postMessage({
            type: 'updatePageContentHeight',
            data: {
              pageId: currPage.value.meta.id,
              contentHeight: clientHeight
            }
          })
          clearInterval(checkUpdated)
        }
      }, 500)
    })
    const dragoverHolderStyle = computed(() => {
      if (!dragoverUIItemId.value) return
      if (dragoverPlacement.value === 'in') return enterPlaceholderStyle.value
      const dom = document.getElementById(dragoverUIItemId.value)
      if (!dom) return
      const rect = dom.getBoundingClientRect()
      const styles: any = ['position:absolute;top:0;left:0;border:2px solid #dc3545;']
      let x = rect.x
      let y = rect.y
      if (dragoverPlacement.value === 'left' || dragoverPlacement.value === 'right') {
        styles.push(`height: ${rect.height + 4}px;`) // 4是outline的宽度
        styles.push('width: 1px')
        if (dragoverPlacement.value === 'right') {
          x += rect.width
        } else {
          x -= 4
        }
        y -= 2
      } else {
        styles.push(`width: ${rect.width + 4}px;`)
        styles.push('height: 1px')
        if (dragoverPlacement.value === 'bottom') {
          y += rect.height
        } else {
          y -= 4
        }
        x -= 2
      }
      styles.push(`transform:translateX(${x}px) translateY(${y}px)`)
      // console.log(styles.join(';'))
      return styles.join(';')
    })
    const enterPlaceholderStyle = computed(() => {
      if (!dragoverUIItemId.value) return
      const dom = document.getElementById(dragoverUIItemId.value)
      if (!dom) return
      const rect = dom.getBoundingClientRect()
      const styles: any = ['position:absolute;top:0;left:0;pointer-events:none !important;border:4px solid #dc3545;']
      styles.push(`width: ${rect.width}px !important;`)
      styles.push(`height: ${rect.height}px !important;`)
      styles.push(`transform:translateX(${rect.x}px) translateY(${rect.y}px) !important`)
      return styles.join(';')
    })
    const backgroundImage = computed(() => {
      // 当设计对话框页面时，背景展示打开该对话框当原始页面截图
      const fromPageId = route.query.fromPageId
      if (!fromPageId) return ''
      return `background:url(${ydhl.api}api/screenshot?pageid=${fromPageId}) no-repeat;background-size: cover`
    })
    const addToastPopup = () => {
      const text ={
        type: 'Text',
        id: ydhl.uuid(10, 16, 'Text'),
        meta: {
          value: 'This is Toast',
          css:{
            foregroundTheme: 'light'
          }
        }
      }

      const type = 'Modal'
      postMessage({
        type: 'addItem',
        data: {
          type: type,
          meta: {
            id: ydhl.uuid(10, 16, type),
            isContainer: true,
            custom:{
              headless: true,
              footless: true
            },
            css: {
              backgroundTheme: 'secondary',
              foregroundTheme: 'light',
              '-': 'move-handler' // 只真对web有用
            },
            title: type
          },
          items: [text],
          placement: 'in',
          pageId: currPage.value.meta.id,
          targetId: currPage.value.meta.id
        }
      })
    }
    const addModal = (items) => {
      const type = 'Modal'
      postMessage({
        type: 'addItem',
        data: {
          type: type,
          meta: {
            id: ydhl.uuid(10, 16, type),
            isContainer: true,
            title: type
          },
          items,
          placement: 'in',
          pageId: currPage.value.meta.id,
          targetId: currPage.value.meta.id
        }
      })
    }
    const addConfirmPopup = () => {
      addModal([{
        type: 'Text',
        placeInParent: 'head',
        meta: {
          id: ydhl.uuid(10, 16, 'Text'),
          value: 'This is head',
          type: 'h3'
        }
      },{
        type: 'Text',
        meta: {
          id: ydhl.uuid(10, 16, 'Text'),
          value: 'This is body',
        }
      },{
        type: 'Button',
        placeInParent: 'foot',
        meta: {
          id: ydhl.uuid(10, 16, 'Button'),
          type: 'button',
          title: 'OK',
          css: {
            backgroundTheme: 'primary'
          }
        }
      },{
        type: 'Button',
        placeInParent: 'foot',
        meta: {
          id: ydhl.uuid(10, 16, 'Button'),
          type: 'button',
          title: 'Cancel',
          css: {
            backgroundTheme: 'secondary'
          }
        }
      }])
    }
    const addPromptPopup = () => {
      addModal([{
        type: 'Text',
        placeInParent: 'head',
        meta: {
          id: ydhl.uuid(10, 16, 'Text'),
          value: 'This is head',
          type: 'h3'
        }
      },{
        type: 'Input',
        meta: {
          title: 'Input',
          custom: {
            inputType: 'Textarea',
          },
          value: 'This is input',
          id: ydhl.uuid(10, 16, 'Input')
        }
      },{
        type: 'Button',
        placeInParent: 'foot',
        meta: {
          id: ydhl.uuid(10, 16, 'Button'),
          type: 'button',
          title: 'OK',
          css: {
            backgroundTheme: 'primary'
          }
        }
      }])
    }
    const addAlertPopup = () => {
      addModal([{
        type: 'Text',
        placeInParent: 'head',
        meta: {
          id: ydhl.uuid(10, 16, 'Text'),
          value: 'This is head',
          type: 'h3'
        }
      },{
        type: 'Text',
        meta: {
          id: ydhl.uuid(10, 16, 'Text'),
          value: 'This is body',
        }
      },{
        type: 'Button',
        placeInParent: 'foot',
        meta: {
          id: ydhl.uuid(10, 16, 'Button'),
          title: 'OK',
          type: 'button',
          css: {
            backgroundTheme: 'primary'
          }
        }
      }])
    }
    const addCustomPopup = () => {
      const type = 'Modal'
      postMessage({
        type: 'addItem',
        data: {
          type: type,
          meta: {
            id: ydhl.uuid(10, 16, type),
            isContainer: true,
            custom:{
              headless: true,
              footless: true
            },
            title: type
          },
          items: [],
          placement: 'in',
          pageId: currPage.value.meta.id,
          targetId: currPage.value.meta.id
        }
      })
    }
    const addPopup = (type) => {
      if (type === 'toast'){
        addToastPopup()
        return
      }
      if (type === 'alert'){
        addAlertPopup()
        return
      }
      if (type === 'confirm'){
        addConfirmPopup()
        return
      }
      if (type === 'prompt'){
        addPromptPopup()
        return
      }
      if (type === 'custom'){
        addCustomPopup()
        return
      }
    }
    const { t } = useI18n()
    const popPlacementStyle = computed(() => {
      // 弹窗时第一个元素只会是Modal, 预览时通过page的布局定位其位置，实际代码web通过layer来实现弹窗
      const rootUI = currPage.value?.items?.[0]
      const placements  = rootUI?.meta?.custom?.position || ['center', 'center']
      let showBackdrop = rootUI?.meta?.custom?.backdrop
      showBackdrop = showBackdrop === undefined || showBackdrop !== 'no' ? true : false
      const items = {
        top: 'start',
        center: 'center',
        bottom: 'end'
      }
      const justify = {
        left: 'start',
        center: 'center',
        right: 'end'
      }
      return `align-items:${items[placements[1]]};justify-content:${justify[placements[0]]};${(showBackdrop ? 'background-color:rgba(0,0,0,0.5)' : '')}`
    })
    return {
      postMessage,
      addPopup,
      backgroundImage,
      isEmptyPopup,
      isPopup,
      currPage,
      t,
      dragoverUIItemId,
      dragoverPlacement,
      dragoverHolderStyle,
      enterPlaceholderStyle,
      popPlacementStyle,
      showEventPanel
    }
  }
}
