
import { useI18n } from 'vue-i18n'
import { computed, watch } from 'vue'
import initUI from '../../Common'
import ColorPicker from '@/components/common/ColorPicker.vue'

export default {
  name: 'StyleUtilities',
  components: { ColorPicker },
  setup (props: any, context: any) {
    const info = initUI()
    const { t } = useI18n()
    const hshadow = info.computedWrap('boxShadowH', 'custom', '')
    const vshadow = info.computedWrap('boxShadowV', 'custom', '')
    const blur = info.computedWrap('boxShadowBlur', 'custom', '')
    const spread = info.computedWrap('boxShadowSpread', 'custom', '')
    const inset = info.computedWrap('boxShadowInset', 'custom', '')
    const color = info.computedWrap('boxShadowColor', 'custom', '')
    watch([hshadow, vshadow, blur, color, inset, spread], (v) => {
      info.setMeta('box-shadow', `${hshadow.value} ${vshadow.value} ${blur.value} ${spread.value} ${color.value} ${inset.value}`, 'style')
    })
    const opacity = computed({
      get: () => {
        return info.getMeta('opacity', 'style') || 1
      },
      set: (v) => {
        info.setMeta('opacity', v || undefined, 'style')
      }
    })
    return {
      ...info,
      t,
      opacity,
      hshadow,
      vshadow,
      blur,
      spread,
      inset,
      color
    }
  }
}
